import { CallbackType } from "@/fetch/payment/helpers";
import { CancelStage } from "src/pages/CancelSubscriptionPage/cancelSubscriptionPage";
import { AddressChangeType } from "src/pages/ChangeAddressPage/changeAddressPage";

const basePath = "/abo";
const slugs = {
  subscription: ":subscriptionId",
  changeType: ":changeType",
  paymentType: ":paymentType",
  cancelStage: ":cancelStage",
  target: ":target",
};

type Params = { [key: string]: string };

// Returns path with slug replaced
const replaceSlugs = (path: string, params: Params) =>
  Object.entries(params).reduce((acc, [k, v]) => acc.replace(k, v), path);

export const routerPaths: Record<keyof typeof routes, string> = {
  cancelSubscription: `${basePath}/abonnement/${slugs.subscription}/si-opp-avisen/${slugs.cancelStage}`,
  deliveryComplaint: `${basePath}/abonnement/${slugs.subscription}/klage-pa-levering`,
  error: `${basePath}/feil`,
  myPage: basePath,
  changeAddress: `${basePath}/abonnement/${slugs.subscription}/adresseendring/${slugs.changeType}`,
  changes: `${basePath}/abonnement/${slugs.subscription}/endring`,
  payment: `${basePath}/abonnement/${slugs.subscription}/betaling`,
  paymentHistory: `${basePath}/abonnement/${slugs.subscription}/betalingshistorikk`,
  temporaryStop: `${basePath}/abonnement/${slugs.subscription}/midlertidig-stans`,
  receipt: `${basePath}/abonnement/${slugs.subscription}/kvittering`,
  callback: `${basePath}/abonnement/${slugs.subscription}/endre-betalingsmetode/${slugs.paymentType}`,
  temporarilyUnavailable: `${basePath}/teknisk-feil`,
  redirect: `${basePath}/r/:target`,
  info: `${basePath}/info`,
};

const routes = {
  myPage: {
    title: { nb: "Min side", nn: "Mi side" },
    path: () => routerPaths.myPage,
  },
  cancelSubscription: {
    title: { nb: "Si opp abonnementet", nn: "Sei opp abonnementet" },
    path: (subscriptionId: string, cancelStage: CancelStage) =>
      replaceSlugs(routerPaths.cancelSubscription, {
        [slugs.subscription]: subscriptionId,
        [slugs.cancelStage]: cancelStage,
      }),
  },
  deliveryComplaint: {
    title: { nb: "Klage på levering", nn: "Klage på levering" },
    path: (subscriptionId: string) =>
      replaceSlugs(routerPaths.deliveryComplaint, {
        [slugs.subscription]: subscriptionId,
      }),
  },
  error: {
    title: { nb: "Feil", nn: "Feil" },
    path: () => routerPaths.error,
  },
  changeAddress: {
    title: { nb: "Endre adresse", nn: "Endre adresse" },
    path: (subscriptionId: string, changeType: AddressChangeType) =>
      replaceSlugs(routerPaths.changeAddress, {
        [slugs.subscription]: subscriptionId,
        [slugs.changeType]: changeType,
      }),
  },
  changes: {
    title: { nb: "Leveringsendringer", nn: "Leveringsendringer" },
    path: (subscriptionId: string) =>
      replaceSlugs(routerPaths.changes, {
        [slugs.subscription]: subscriptionId,
      }),
  },
  payment: {
    title: { nb: "Betalingsoversikt", nn: "Betalingsoversikt" },
    path: (subscriptionId: string) =>
      replaceSlugs(routerPaths.payment, {
        [slugs.subscription]: subscriptionId,
      }),
  },
  paymentHistory: {
    title: { nb: "Betalingshistorikk", nn: "Betalingshistorikk" },
    path: (subscriptionId: string) =>
      replaceSlugs(routerPaths.paymentHistory, {
        [slugs.subscription]: subscriptionId,
      }),
  },
  temporaryStop: {
    title: { nb: "Midlertidig stans", nn: "Mellombels stopp" },
    path: (subscriptionId: string) =>
      replaceSlugs(routerPaths.temporaryStop, {
        [slugs.subscription]: subscriptionId,
      }),
  },
  receipt: {
    title: { nb: "Kvittering", nn: "Kvittering" },
    path: (subscriptionId: string) =>
      replaceSlugs(routerPaths.receipt, {
        [slugs.subscription]: subscriptionId,
      }),
  },
  callback: {
    title: { nb: "Behandler", nn: "Behandler" },
    path: (subscriptionId: string, paymentType: CallbackType) =>
      replaceSlugs(routerPaths.callback, {
        [slugs.subscription]: subscriptionId,
        [slugs.paymentType]: paymentType,
      }),
  },
  temporarilyUnavailable: {
    title: { nb: "Teknisk feil", nn: "Teknisk feil" },
    path: () => routerPaths.temporarilyUnavailable,
  },
  redirect: {
    title: null,
    path: (target: string) =>
      replaceSlugs(routerPaths.callback, {
        [slugs.target]: target,
      }),
  },
  info: {
    title: { nb: "Info", nn: "Info" },
    path: () => routerPaths.info,
  },
};

export default routes;
