import React, { useEffect, useRef } from "react";
import { styled } from "styled-components";

import { visuallyHidden } from "../../../styles";

import AddressChangeServerError from "./addressChangeServerError";

import { TextInputField } from "@/components/InputField";
import PlusToggle from "@/components/PlusToggle";
import { FormErrors, FormProps } from "@/utils/form";
import { useTranslate } from "@/i18n";
import colors from "@/theme/colors";
import { log } from "@/logging/logger";

export type InputAddress = {
  streetName: string;
  streetNumber: string;
  entrance: string;
  floor: string;
  apartment: string;
  postalCode: string;
  city: string;
  careOf: string;
};

export type Props = {
  errors: FormErrors<InputAddress>;
  propsForField: FormProps<InputAddress>["propsForField"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: (key: any, value: any) => void;
  firstInvalidInput: string;
};

const AddressFieldset: React.FC<Props> = ({
  errors,
  propsForField,
  setValue,
  firstInvalidInput,
}: Props) => {
  const t = useTranslate(messages);
  const errorRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (firstInvalidInput && errorRef.current) {
      errorRef.current.focus();
    } else if (firstInvalidInput && !errorRef.current) {
      log.error(
        `Invalid input received, but no matching reference for ${firstInvalidInput}`,
      );
    }
  }, [firstInvalidInput]);

  return (
    <Fieldset aria-live="polite">
      <legend>{t("legend")}</legend>
      {errors.global && <AddressChangeServerError error={errors.global} />}
      <PlusToggle
        onToggleClose={() => setValue && setValue("careOf", "")}
        openText={t("addCareOf")}
        closeText={t("removeCareOf")}
      >
        <CareOf
          {...propsForField("careOf")}
          description={t("careOf")}
          autoComplete="off"
        />
      </PlusToggle>

      <StreetSection>
        <StreetName
          {...propsForField("streetName")}
          description={t("streetName")}
          autoComplete="off"
          required
          ref={firstInvalidInput === "streetName" ? errorRef : null}
        />
        <StreetNumber
          {...propsForField("streetNumber")}
          description={t("streetNumber")}
          autoComplete="off"
          required
          ref={firstInvalidInput === "streetNumber" ? errorRef : null}
        />
      </StreetSection>
      <ApartmentSection>
        <Entrance
          {...propsForField("entrance")}
          description={t("entrance")}
          autoComplete="off"
          ref={firstInvalidInput === "entrance" ? errorRef : null}
        />
        <Floor
          {...propsForField("floor")}
          description={t("floor")}
          autoComplete="off"
          ref={firstInvalidInput === "floor" ? errorRef : null}
        />
        <ApartmentNumber
          {...propsForField("apartment")}
          description={t("apartment")}
          autoComplete="off"
          ref={firstInvalidInput === "apartment" ? errorRef : null}
        />
      </ApartmentSection>
      <CitySection>
        <PostalCode
          {...propsForField("postalCode")}
          description={t("postalCode")}
          autoComplete="off"
          required
          ref={firstInvalidInput === "postalCode" ? errorRef : null}
        />
        <City
          {...propsForField("city")}
          description={t("city")}
          autoComplete="off"
          required
          ref={firstInvalidInput === "city" ? errorRef : null}
        />
      </CitySection>
      <FormHasRequiredFields>
        <span>*</span> {t("required")}
      </FormHasRequiredFields>
    </Fieldset>
  );
};

const FormHasRequiredFields = styled.i`
  color: ${colors.grayText};
  text-align: right;
  & span {
    color: ${colors.errorRedDark};
  }
`;

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0;
  padding: 0;
  & legend {
    ${visuallyHidden}
  }
`;

const AddressInput = styled(TextInputField)`
  margin: 5px 10px;
  min-width: 120px;
`;

const StreetSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 15px -10px;
`;

const StreetName = styled(AddressInput)`
  flex: 5;
  min-width: 260px;
`;

const StreetNumber = styled(AddressInput)`
  flex: 1;
`;

const ApartmentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px 15px -10px;
`;

const Entrance = styled(AddressInput)`
  flex: 1;
`;

const Floor = styled(AddressInput)`
  flex: 1;
`;

const ApartmentNumber = styled(AddressInput)`
  flex: 1;
`;

const CitySection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0 -10px;
`;

const PostalCode = styled(AddressInput)`
  flex: 1;
`;

const City = styled(AddressInput)`
  flex: 5;
`;

const CareOf = styled(AddressInput)`
  margin: 0;
`;

const messages = {
  legend: {
    nb: "Adresse",
  },
  streetName: {
    nb: "Gate",
  },
  streetNumber: {
    nb: "Nummer",
  },
  entrance: {
    nb: "Oppgang",
  },
  floor: {
    nb: "Etasje",
  },
  apartment: {
    nb: "Leilighetsnr",
  },
  postalCode: {
    nb: "Postnr",
  },
  city: {
    nb: "Sted",
  },
  careOf: {
    nb: "c/o",
  },
  addCareOf: {
    nb: "Legg til c/o (valgfritt)",
  },
  removeCareOf: {
    nb: "Fjern c/o",
  },
  required: {
    nb: "markerer påkrevd felt.",
  },
};

export default AddressFieldset;
