import { styled } from "styled-components";

import colors from "@/theme/colors";

type Props = {
  $primary?: boolean;
  flexDirection?: string;
  $borderTopColor?: string;
};

export const Sheet = styled.div<Props>`
  padding: 25px;
  margin: 0 0 20px 0;
  box-shadow: 0 1px 4px ${colors.transparentBlack};
  background: ${colors.white};
  ${(props) =>
    props.$primary &&
    `
    border-top: 5px solid ${
      props.$borderTopColor || props.theme.primaryColor || "teal"
    };
  `}
  > h2:first-of-type {
    margin: 0 0 20px 0;
  }
  ${(props) =>
    props.flexDirection &&
    `
      display: flex;
      flex-direction: ${props.flexDirection};
    `}
`;

export const InnerSheet = styled.div`
  background-color: ${colors.amediaLightGray};
  padding: 20px 15px;
`;

export default Sheet;
