export function createSkipToContentLink(locale: string) {
  const skipToContentLink = document.createElement("a");
  skipToContentLink.href = "#app";
  skipToContentLink.id = "skip-to-main-content";

  skipToContentLink.textContent =
    locale === "nn"
      ? "Gå til sidas hovudinnhald"
      : "Gå til sidens hovedinnhold";

  const body = document.querySelector("body");
  if (body) {
    body.prepend(skipToContentLink);
  }
}
