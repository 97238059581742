import { styled, keyframes } from "styled-components";
import React, { useEffect, useState, PropsWithChildren } from "react";

import colors from "@/theme/colors";

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SpinnerFrames = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div<{ $visible: boolean }>`
  animation: ${FadeIn} 3s;
  text-align: center;
  visibility: ${(props) => (props.$visible ? "initial" : "hidden")};
`;

const StyledSpinner = styled.div<{
  color: string;
}>`
  margin: 15px auto;
  position: relative;
  border: 5px solid rgba(0, 0, 0, 0.15);
  border-left: 5px solid ${({ color }) => color};
  transform: translateZ(0);
  animation: ${SpinnerFrames} 1.1s infinite linear;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

type Props = {
  color?: string;
  timeToAppear?: number;
};

const Spinner: React.FC<PropsWithChildren<Props>> = ({
  color,
  timeToAppear,
  children,
}) => {
  const [isHidden, setHidden] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => setHidden(false), timeToAppear || 1000);
    return () => clearTimeout(timeout);
  }, [setHidden, timeToAppear]);

  return (
    <Wrapper $visible={!isHidden} data-testid="spinner">
      <StyledSpinner color={color || colors.contrastGrayDark} />
      {children}
    </Wrapper>
  );
};

export default Spinner;
