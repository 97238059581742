import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import { Sheet } from "@/components/Sheet";
import Heading from "@/components/Heading";
import { useTranslate } from "@/i18n";
import CustomerServiceInfo from "@/components/CustomerServiceInfo";
import AppContext from "@/contexts/appContext";
import PageHeader from "@/components/PageHeader";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";

const CancelUnavailable: React.FC = () => {
  const t = useTranslate(messages);
  const { user } = useOutletContext<SubscriptionOutlet>();
  const context = useContext(AppContext);
  const supportEmail = context.gaia.customerService.email;

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Sheet $primary>
        <Heading>{t("heading", { firstName: user.name.first })}</Heading>
        <p>
          {t("notYet")}
          <br />
          {t("tryAgain")}
        </p>
        <p>{t("ifYouReally")}</p>
        <CustomerServiceInfo />
        <a href={`mailto:${supportEmail}`}>{t("mailTo")}</a>
      </Sheet>
    </>
  );
};

export default CancelUnavailable;

const messages = {
  title: { nb: "Si opp abonnementet", nn: "Sei opp abonnementet" },
  heading: {
    nb: "Så synd at du vil si opp, {{firstName}}",
    nn: "Så synd at du vil seie opp, {{firstName}}",
  },
  notYet: {
    nb: "Det oppsto en feil og du kan dessverre ikke si opp på Min side for øyeblikket.",
    nn: "Det oppsto ein feil og du kan dessverre ikkje seie opp på Mi Side for augeblikket",
  },
  tryAgain: {
    nb: "Prøv igjen i morgen eller kontakt kundeservice",
    nn: "Prøv igjen i morgon eller kontakt kundeservice",
  },
  ifYouReally: {
    nb: "Ønsker du likevel å si opp nå, kan du ta kontakt med kundeservice:",
    nn: "Ønskjer du likevel å sei opp no, kan du ta kontakt med kundeservice:",
  },
  mailTo: {
    nb: "Send oss en e-post",
    nn: "Send oss ein e-post",
  },
};
