import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import { NavigateToErrorPage } from "../ErrorPage/errorPage";

import PauseDeliveryForm from "./components/pauseDeliveryForm";

import BreadCrumbs from "@/components/Breadcrumbs";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import SubscriptionTypeNotSupported from "@/components/SubscriptionTypeNotSupported/subscriptionTypeNotSupported";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import { includesPaperDelivery } from "@/utils/productFeatures";
import { useGetAddressChanges } from "@/fetch/address/getAddressChanges";

const PauseDeliveryPage: React.FC = () => {
  const t = useTranslate(messages);
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const title = routes.temporaryStop.title[locale];
  pageTitle.set(pageTitle.create(name.short, title));

  const { addressChanges, error } = useGetAddressChanges({
    subscriptionId: subscription.id,
  });

  if (error) {
    log.error("Failed to fetch address changes.", error);
    return <NavigateToErrorPage errorCode="address" />;
  }

  if (!includesPaperDelivery(subscription.product.productFeatures)) {
    return <SubscriptionTypeNotSupported subscription={subscription} />;
  }

  return (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <PageHeader>{title}</PageHeader>
      <Sheet>{t("warning")}</Sheet>
      <Sheet>
        <PauseDeliveryForm
          subscription={subscription}
          addressChanges={addressChanges}
        />
      </Sheet>
    </>
  );
};

export default PauseDeliveryPage;

const messages = {
  warning: {
    nb: "PS! Det er kun levering av papiravisen din som stanses i perioden du velger. Din digitale tilgang stanses ikke.",
    nn: "PS! Det er kun levering av papiravisa di som vert stoppa i perioden du vel. Din digitale tilgang stoppar ikkje.",
  },
  error: {
    nb: "Teknisk feil ved uthenting av adresseendringer",
  },
};
