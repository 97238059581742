import React, { useContext } from "react";

import ChatView from "./components/chatView";

import { useChatStatus } from "@/services/internal/chat";
import { log } from "@/logging/logger";
import AppContext from "@/contexts/appContext";

const Chat: React.FC = () => {
  const context = useContext(AppContext);
  const { site } = context;
  const { open, error } = useChatStatus();

  if (error && site.chatConfig) {
    log.error("Failed to load chat status", error);
  }

  if (!open || !site.chatConfig || error) {
    // Chat is closed or not configured
    return null;
  }

  return <ChatView chatConfig={site.chatConfig} />;
};

export default Chat;
