import "@amedia/brick-icon";
import "@amedia/brick-icons";
import { styled } from "styled-components";

type SupportedIcons = "chevron-up" | "chevron-down";

type Props = {
  iconId: SupportedIcons;
  width?: string;
  height?: string;
};

const IconContainer = styled.div<{ height?: string; width?: string }>`
  & brick-icon-v2 > div > svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    margin: 4px;
  }
`;

const BrickIcon = ({ iconId, width, height }: Props) => (
  <IconContainer width={width} height={height}>
    <brick-icon-v2 data-icon-id={iconId} />
  </IconContainer>
);

export default BrickIcon;
