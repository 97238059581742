import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "styled-components";

import { CurrentSubscriptionCard } from "../../components/CurrentSubscriptionCard/currentSubscriptionCard";

import { infoText } from "./infoText";
import { SubscriptionInfoCard } from "./subscriptionInfoCard";
import { PodcastInfo } from "./podcastInfo";
import { DirektesportInfo } from "./direktesportInfo";
import { AltInfo } from "./altInfo";
import { HundrePlussAviser } from "./hundrePlussAviser";
import {
  eaviskioskenButton,
  FALLBACK_IMG_URI,
  myPageButton,
  shareSubscriptionButton,
} from "./utils";
import { productFeatureButtons } from "./productFeatureButtons";

import AppContext from "@/contexts/appContext";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useTranslate } from "@/i18n";
import {
  includesDirektesport,
  includesEPaper,
  includesPlussalt,
  includesPodcast,
  includesShareable,
} from "@/utils/productFeatures";
import BreadCrumbs from "@/components/Breadcrumbs";
import routes from "@/routes/routes";
import { useMediaQuery } from "@/hooks/useMediaQuery";

const SubTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: var(--brick-fonts-baseHeadlineM);
  font-size: var(--brick-fontSizes-baseHeadlineM);
  font-weight: var(--brick-fontWeights-baseHeadlineM);
  line-height: var(--brick-lineHeights-baseHeadlineM);
  letter-spacing: var(--brick-letterSpacings-baseHeadlineM);
  color: var(--brick-colors-baseFg);
  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 1020px) {
    margin: 16px;
    justify-content: flex-start;
  }

  @media (max-width: 680px) {
    margin: 16px;
    justify-content: center;
  }
`;

const BreadCrumbContainer = styled.div`
  margin-bottom: 24px;

  @media (max-width: 1020px) {
    margin: 16px;
  }

  @media (max-width: 680px) {
    margin: 0px;
  }
`;

type SubscriptionInfoCardProps = {
  subscription: Subscription;
};

export const SubscriptionInfo = ({
  subscription,
}: SubscriptionInfoCardProps) => {
  const [params] = useSearchParams();
  const t = useTranslate(infoText);
  const context = useContext(AppContext);
  const isMobileDevice = useMediaQuery("(max-width: 680px)");
  const { name, locale } = context.site;
  const publicationDomain = name.short;
  const param = params.get("context");
  const title =
    param === "welcome" ? t("dittAbonnement") : routes.info.title[locale];

  const hasPlussAlt = includesPlussalt(subscription.product.productFeatures);

  const hasPodcast = includesPodcast(subscription.product.productFeatures);

  const hasDirektesport = includesDirektesport(
    subscription.product.productFeatures,
  );
  const hasEpaper = includesEPaper(subscription.product.productFeatures);

  const isShareable = includesShareable(subscription.product.productFeatures);

  const APP_STORE_PATH = "https://apps.apple.com/no/app/id";

  const iosAppId = context.site.apps?.nyhetsappen?.ios?.appId
    ? `${APP_STORE_PATH}/${context.site.apps.nyhetsappen.ios.appId}`
    : "https://apps.apple.com/no/developer/amedia-as/id423980137?l=da";

  const GOOGLE_PLAY_PATH = "https://play.google.com/store/apps/details?id=";

  const androidAppId = context.site.apps?.nyhetsappen?.android?.appId
    ? `${GOOGLE_PLAY_PATH}${context.site.apps?.nyhetsappen?.android.appId}`
    : "https://play.google.com/store/apps/developer?id=Amedia&hl=no&gl=US";

  const downloadAppButton = [
    {
      id: "ios",
      label: isMobileDevice ? t("ios") : t("downloadIos"),
      href: iosAppId,
    },
    {
      id: "android",
      label: isMobileDevice ? t("android") : t("downloadAndroid"),
      href: androidAppId,
    },
  ];

  const eavisButtons = [
    {
      id: "ios",
      label: isMobileDevice ? t("ios") : t("downloadIos"),
      href: "https://apps.apple.com/no/app/aviskiosken/id6472655537",
    },
    {
      id: "android",
      label: isMobileDevice ? t("android") : t("downloadAndroid"),
      href: "https://play.google.com/store/apps/developer?id=Amedia&hl=no",
    },
  ];
  const downloadAppDescription = hasEpaper
    ? t("downloadDescriptionEavis")
    : t("downloadDescription");

  const shareableDescription = hasPlussAlt
    ? t("sharePlussAltSubscription", { publicationDomain })
    : t("shareSubscription");

  const welcomeDescription =
    param === "welcome"
      ? t("newSubDescription", {
          subscriptionName: subscription.name,
          publicationDomain,
        })
      : subscription.catalanaTexts.epvTeaser ?? "";

  return (
    <>
      <BreadCrumbContainer>
        <BreadCrumbs>{title}</BreadCrumbs>
      </BreadCrumbContainer>
      <CurrentSubscriptionCard
        title={
          param === "welcome" ? t("newSubscriptionTitle") : subscription.name
        }
        description={welcomeDescription}
        imageUrl={subscription.catalanaTexts.imageUrl ?? FALLBACK_IMG_URI}
        productFeatures={subscription.product.productFeatures}
        productFeatureButtons={productFeatureButtons}
      />
      <SubTitle>{t("title")}</SubTitle>
      {hasPlussAlt && <HundrePlussAviser />}
      <SubscriptionInfoCard
        id="digital"
        title={`Last ned ${name.full}-appen`}
        description={[downloadAppDescription, t("downloadSubDescription")]}
        buttons={downloadAppButton}
        helperText={t("helperText")}
        type="lastNed"
      />
      {isShareable && (
        <SubscriptionInfoCard
          id="shareable"
          title={t("subTitle2")}
          description={[shareableDescription]}
          type="sharing"
          buttons={shareSubscriptionButton}
        />
      )}
      {hasPodcast && <PodcastInfo publicationDomain={publicationDomain} />}
      {hasDirektesport && (
        <DirektesportInfo publicationDomain={publicationDomain} />
      )}
      {hasPlussAlt && <AltInfo publicationDomain={publicationDomain} />}
      {hasEpaper && (
        <SubscriptionInfoCard
          id="paper_weekdays"
          title="eAvis"
          description={[
            t("eAvis", {
              publicationDomain: publicationDomain.toLocaleLowerCase(),
            }),
          ]}
          type="eAvis"
          helperText={t("helperText")}
          buttons={eavisButtons}
        />
      )}
      {hasEpaper && (
        <SubscriptionInfoCard
          id="epaper"
          title={t("eAviskioskenTitle")}
          description={[
            t("eAviskiosken", {
              publicationDomain: publicationDomain.toLowerCase(),
            }),
          ]}
          type="eAviskiosken"
          buttons={eaviskioskenButton}
        />
      )}
      <SubscriptionInfoCard
        id="minside"
        title={t("minSideTitle")}
        description={[t("minSide")]}
        type="minSide"
        buttons={myPageButton}
      />
    </>
  );
};
export default SubscriptionInfo;
