import { SubscriptionContentType } from "./subscriptionInfoCard";

export const altnoButton = [
  {
    id: "altno",
    label: "Gå til alt.no",
    href: "https://www.alt.no/",
  },
];

export const hundrePlussAviserButton = [
  {
    id: "hundrepluss",
    label: "Se aviser som er med i +Alt",
    href: "https://www.plussalt.no/aviser",
  },
];

export const eaviskioskenButton = [
  {
    id: "eaviskiosken",
    label: "Gå til eAviskiosken",
    href: "/vis/aviskiosk",
  },
];

export const shareSubscriptionButton = [
  {
    id: "share",
    label: "Del abonnement",
    href: "https://www.aid.no/aid/familie",
  },
];

export const myPageButton = [
  {
    id: "my-page",
    label: "Gå til min side",
    href: "/abo",
  },
];

export const illustrationSource = (type: SubscriptionContentType) => {
  const illustrations = {
    alt: "alt-served",
    direktesport: "sport-spectator",
    lastNed: "read-digital",
    sharing: "share-family",
    eAvis: "elder-eavis",
    eAviskiosken: "change-paperplane",
    papiravis: "read-newspaper",
    podcast: "audio-dreaming",
    hundrePlussAviser: "plussalt-screentime",
    minSide: "purchase-handheart",
    default: "read-digital",
  };
  const illustration = illustrations[type] || illustrations.default;
  return `https://assets.acdn.no/pkg/@amedia/brick-illustrations/v1/svg/${illustration}.svg`;
};

export const FALLBACK_IMG_URI =
  "https://assets.acdn.no/pkg/@amedia/brick-illustrations/v1/svg/read-digital.svg";

export const generateRandomCovers = (count: number, images: string[]) => {
  const shuffledImages = [...images];
  for (let i = shuffledImages.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledImages[i], shuffledImages[j]] = [
      shuffledImages[j],
      shuffledImages[i],
    ];
  }
  return shuffledImages.slice(0, count);
};
