import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import { NavigateToErrorPage } from "../ErrorPage/errorPage";

import BreadCrumbs from "@/components/Breadcrumbs";
import Invoice from "@/components/Invoice/invoice";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import { useGetPaymentHistory } from "@/fetch/payment/getPaymentHistory";

const PaymentHistoryPage: React.FC = () => {
  const t = useTranslate(messages);
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const title = routes.paymentHistory.title[locale];

  pageTitle.set(pageTitle.create(name.short, title));

  const { paymentHistory, error } = useGetPaymentHistory({
    subscriptionId: subscription?.id,
  });

  if (error) {
    log.error("Failed to fetch payment history", error);
    return <NavigateToErrorPage errorCode="paymentHistory" />;
  }

  const sortedPaymentHistory =
    paymentHistory &&
    [...paymentHistory].sort(
      (a, b) => new Date(b.dueDate).valueOf() - new Date(a.dueDate).valueOf(),
    );

  return (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <PageHeader>{title}</PageHeader>
      {!sortedPaymentHistory || sortedPaymentHistory.length === 0 ? (
        <Sheet>{t("noPayments")}</Sheet>
      ) : (
        <>
          {sortedPaymentHistory.map((payment, key) => (
            <Sheet key={key}>
              <Invoice invoice={payment} />
            </Sheet>
          ))}
          <Sheet>{t("paymentHistoryCap")}</Sheet>
        </>
      )}
    </>
  );
};
export default PaymentHistoryPage;

const messages = {
  noPayments: {
    nb: "Det er ingen betalinger de siste 12 månedene. Dersom du har tidligere betalinger du ønsker å se, må du ta kontakt med kundesenteret.",
    nn: "Det er ingen betalingar dei siste 12 månadane. Dersom du har tidligare betalingar du ønskjer å sjå, må du ta kontakt med kundesenteret.",
  },
  paymentHistoryCap: {
    nb: "Listen over viser kun betalinger utført innenfor de siste 12 månedene. Dersom du har tidligere betalinger du ønsker å se, må du ta kontakt med kundesenteret.",
    nn: "Lista over viser kun betalingar utført innanfor dei siste 12 månadane. Dersom du har tidlegare betalingar du ønskjer å sjå, må du ta kontakt med kundesenteret.",
  },
  error: {
    nb: "Teknisk feil ved uthenting av betalingshistorikk",
  },
};
