import React from "react";
import { styled } from "styled-components";

import colors from "@/theme/colors";

type StyleProps = {
  $error?: boolean;
  $large?: boolean;
};

export type DatePickerProps = StyleProps & {
  selectedDate: string;
  minDate?: string;
  maxDate?: string;
  onChange: (date: string) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
};

const DatePicker = (props: DatePickerProps) => (
  <Input
    type="date"
    value={props.selectedDate}
    min={props.minDate}
    max={props.maxDate}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      props.onChange(event.currentTarget.value);
    }}
    onBlur={props.onBlur}
    $error={props.$error}
    $large={props.$large}
  />
);

const Input = styled.input<StyleProps>`
  display: block;
  background-color: ${colors.white};
  font: inherit;
  padding: 0 0.7em;
  border: 2px solid
    ${({ $error }) => ($error ? colors.errorRedDark : colors.inactiveGray)};
  width: 100%;
  height: ${({ $large }) => ($large ? "3em" : "2.5em")};
  position: relative;

  &:focus {
    border-color: ${colors.almostBlack};
    outline: none;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    color: transparent;
    background: transparent;
    z-index: 1;
  }

  &:after {
    content: "\\1F4C5";
    position: absolute;
    right: 4px;
    top: 3px;
    font-size: 20px;
  }
`;

export default DatePicker;
