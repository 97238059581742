import React, { PropsWithChildren } from "react";
import { styled } from "styled-components";

type Props = PropsWithChildren & {
  text: string;
  centered?: boolean;
};

const SubHeader: React.FC<Props> = (props) => (
  <>
    <Header {...props}> {props.text} </Header>
    {props.children}
  </>
);

export default SubHeader;

const Header = styled.h2<Props>(
  (props) => `
  margin-top: 35px;
  font-size: 18px;
  text-align: ${props.centered ? "center" : "left"};
  margin-bottom: ${props.children ? "0" : "20px"};
  margin-bottom: "0px";
`,
);
