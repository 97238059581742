export const Checkmark = (color: string, iconColor: string) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6658_14682)">
      <circle cx="12" cy="12" r="12" fill={color} />
      <path
        d="M8.9936 15.4676L5.08201 11.736L3.75 12.9978L8.9936 18L20.25 7.26174L18.9274 6L8.9936 15.4676Z"
        fill={iconColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_6658_14682">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
