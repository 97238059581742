import React, { useContext } from "react";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import AppContext from "@/contexts/appContext";
import { log } from "@/logging/logger";
import { array } from "@/utils/array";
import LinkButton from "@/components/LinkButton";
import { useOfferingFetcher } from "@/fetch/useOfferingFetcher";

type Props = {
  subscription: Subscription;
};

const PlussaltUpgradeAvailable: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const { locale } = useContext(AppContext).site;

  const accessFeatures = ["plussalt"];
  const { offerings, error } = useOfferingFetcher(
    {
      channel: "product_change",
      publicationAccess: [{ publicationDomain: "www.alt.no", accessFeatures }],
    },
    locale,
  );

  if (error) {
    log.error("useOfferingFetcher failed", error);
  }

  if (offerings.length === 0) {
    return null;
  }
  const offering = offerings[0];

  const offeringsUrl = new URL(`${window.location.origin}/tilbud/utvalgt`);
  offeringsUrl.searchParams.append("access_features", accessFeatures.join(","));

  const currentMonthlyPrice = Math.round(
    subscription.currentRate.rate.totalAmount /
      subscription.currentRate.billingFrequency,
  );

  const regularCommercialTerms = array.last(offering.commercialTerms);
  const duration = regularCommercialTerms.duration.length || 1;

  const offerMonthlyPrice = Math.round(
    regularCommercialTerms.price.amount / duration,
  );
  const monthPriceDiff = Math.round(offerMonthlyPrice - currentMonthlyPrice);

  return (
    <Sheet $primary>
      <h2>{t("title", { price: monthPriceDiff })}</h2>
      <p>{t("description")}</p>
      <LinkButton href={offeringsUrl.href}>{t("cta")}</LinkButton>
    </Sheet>
  );
};

export default PlussaltUpgradeAvailable;

const messages = {
  title: {
    nb: "Les over 100 aviser for {{price}} kr ekstra per måned",
    nn: "Les over 100 aviser for {{price}} kr ekstra per månad",
  },
  description: {
    nb: "Endre abonnementet ditt til +Alt, og få digital tilgang til over 100 andre aviser.",
    nn: "Endre abonnementet ditt til +Alt, og få digital tilgang til over 100 andre aviser.",
  },
  cta: {
    nb: "Endre nå",
    nn: "Endre nå",
  },
};
