import { styled } from "styled-components";

import { PreviewCovers } from "./previewCovers";
import { productFeatureColor } from "./productFeatureButtons";

import { Checkmark } from "@/public/icons/Checkmark";

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 645px;
  margin-bottom: 32px;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  & h4 {
    margin: 0;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 42px;

  & p {
    margin: 0;
  }
`;

const PreviewCoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: 42px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export interface ExpandedContent {
  id: string;
  title: string;
  content?: string;
  subContent?: string;
  previewImage?: boolean;
}

export type SubscriptionType =
  | "direktesport"
  | "podcast"
  | "alt"
  | "hundrePlussAviser";

interface ExpandedSubscriptionContainerProps {
  contentList: ExpandedContent[];
  type: SubscriptionType;
}

export const ExpandedSubscriptionContainer = ({
  contentList,
  type,
}: ExpandedSubscriptionContainerProps) => {
  const CheckmarkIcon = {
    podcast: Checkmark(productFeatureColor.podcast, "#FF7033"),
    direktesport: Checkmark(productFeatureColor.direktesport, "#68ADFF"),
    alt: Checkmark(productFeatureColor.altno, "#FF7033"),
    hundrePlussAviser: Checkmark(productFeatureColor.plussalt, "#9D53D5"),
  };

  return (
    <DescriptionContainer>
      {contentList.map((cnt) => (
        <div key={cnt.id} style={{ marginBottom: 32 }}>
          <List>
            <div style={{ paddingRight: 18, margin: 0 }}>
              {CheckmarkIcon[type]}
            </div>
            <h4>{cnt.title}</h4>
          </List>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <TextContainer>
              <p>{cnt.content}</p>
              {cnt.subContent && (
                <p style={{ marginTop: 16 }}>{cnt.subContent}</p>
              )}
            </TextContainer>
          </div>
          {cnt.previewImage && (
            <PreviewCoverContainer>
              <PreviewCovers type={type} />
            </PreviewCoverContainer>
          )}
        </div>
      ))}
    </DescriptionContainer>
  );
};
export default ExpandedSubscriptionContainer;
