import React from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import SigningStatus from "./signingStatus";
import CurrentPaymentMethod from "./currentPaymentMethod";
import NextInvoice from "./nextInvoice";
import ChangePaymentMethod from "./changePaymentMethod";

import { useTranslate } from "@/i18n";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import routes from "@/routes/routes";
import { Sheet } from "@/components/Sheet";
import { log } from "@/logging/logger";
import Spinner from "@/components/Spinner";
import { useGetPaymentMethodById } from "@/fetch/payment/getPaymentMethodById";
import { PaymentStatusType } from "@/fetch/payment/helpers";

type Props = {
  subscription: Subscription;
};

const PaymentOverview: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const { state } = useLocation();
  const paymentType: PaymentStatusType | undefined = state?.paymentType;

  const isCreditcardChange = paymentType === "replaceCard";

  const { paymentMethod, error } = useGetPaymentMethodById({
    id: subscription.paymentMethodId,
  });

  if (error && !isCreditcardChange) {
    log.error("Failed to fetch payment method", error);
    return <Navigate to={routes.error.path()} />;
  }

  if (!paymentMethod) {
    if (isCreditcardChange && error) {
      return <SigningStatus />;
    }
    return <Spinner />;
  }

  return (
    <>
      <SigningStatus />
      <NextInvoice subscription={subscription} paymentMethod={paymentMethod} />
      <Sheet>
        <Link to={routes.paymentHistory.path(subscription.id)}>
          {t("paymentHistoryLink")}
        </Link>
      </Sheet>
      <CurrentPaymentMethod
        subscription={subscription}
        paymentMethod={paymentMethod}
      />
      <ChangePaymentMethod
        subscription={subscription}
        paymentMethod={paymentMethod}
      />
    </>
  );
};

export default PaymentOverview;

const messages = {
  paymentHistoryLink: {
    nb: "Betalingshistorikk",
    nn: "Betalingshistorikk",
  },
  changePaymentMethod: {
    nb: "Bytt betalingsmåte",
    nn: "Byt betalingsmåte",
  },
};
