import React, { ComponentType, ReactNode, PropsWithChildren } from "react";
import {
  MdInfoOutline,
  MdErrorOutline,
  MdLightbulbOutline,
} from "react-icons/md";
import { styled } from "styled-components";

import colors from "@/theme/colors";

type Props = PropsWithChildren & {
  title?: string;
  icon: ReactNode;
  className?: string;
  background: string;
  color: string;
  boxRef?: React.RefObject<HTMLDivElement>;
};

type ExternalProps = PropsWithChildren & {
  title?: string;
  className?: string;
  boxRef?: React.RefObject<HTMLDivElement>;
  background?: string;
};

export const InfoBox: ComponentType<ExternalProps> = ({
  title,
  children,
  className,
  background,
}) => (
  <BaseBox
    title={title}
    icon={<InfoIcon color={colors.almostBlack} />}
    className={className}
    background={background || colors.infoBackground}
    color={colors.almostBlack}
  >
    {children}
  </BaseBox>
);

const BaseBox: React.FC<Props> = ({
  title,
  icon,
  children,
  className,
  background,
  color,
  boxRef,
}) => (
  <Wrapper className={className} background={background} ref={boxRef}>
    <IconWrapper>{icon}</IconWrapper>
    <ContentWrapper color={color}>
      {title && <Title color={color}>{title}</Title>}
      <Content>{children}</Content>
    </ContentWrapper>
  </Wrapper>
);

export const SuccessBox: ComponentType<ExternalProps> = ({
  title,
  children,
  className,
}) => (
  <BaseBox
    title={title}
    icon={<SuccessIcon color={colors.almostWhite} />}
    className={className}
    background={colors.okGreen}
    color={colors.almostWhite}
  >
    {children}
  </BaseBox>
);

export const FailureBox: ComponentType<ExternalProps> = ({
  title,
  boxRef,
  children,
  className,
}) => (
  <BaseBox
    title={title}
    icon={<FailureIcon color={colors.almostWhite} />}
    className={className}
    background={colors.errorRedDarker}
    color={colors.almostWhite}
    boxRef={boxRef}
  >
    {children}
  </BaseBox>
);

const paddingSize = "20px";

const Wrapper = styled.div<{ background: string }>`
  padding: ${paddingSize};
  display: flex;
  background: ${(props) => props.background};
  word-break: break-word;
`;

const IconWrapper = styled.div`
  margin: 0 ${paddingSize} 0 0px;
`;
const InfoIcon = styled(MdInfoOutline)<{ color: string }>`
  height: 35px;
  width: 35px;
  color: ${(props) => props.color};
`;

const SuccessIcon = styled(MdLightbulbOutline)<{ color: string }>`
  height: 35px;
  width: 35px;
  color: ${(props) => props.color};
  vertical-align: center;
`;

const FailureIcon = styled(MdErrorOutline)<{ color: string }>`
  height: 35px;
  width: 35px;
  color: ${(props) => props.color};
`;

const ContentWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${(props) => props.color};
`;

const Content = styled.div`
  width: 100%;
  a {
    color: ${colors.blue};
  }
`;

const Title = styled.h3<{ color: string }>`
  margin: 5px 0 20px 0;
  font-weight: bold;
  color: ${(props) => props.color};
`;
