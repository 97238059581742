import React, { useContext } from "react";
import { styled } from "styled-components";

import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import { aidUrl } from "@/routes/external";
import { ProductFeature } from "@/fetch/mappers/subscriptionMapper";

type Props = {
  productFeatures: ProductFeature[];
  vacantSpacesInFamily: number;
};

const LostProductFeatures: React.FC<Props> = ({
  productFeatures,
  vacantSpacesInFamily,
}) => {
  const t = useTranslate(messages);
  const { domain } = useContext(AppContext).site;

  const lostProductFeaturesTexts = [
    {
      code: "digital",
      title: t("digitalTitle"),
      texts: [t("digitalText", { domain })],
    },
    {
      code: "altno",
      title: t("altnoTitle"),
      texts: [t("altnoText")],
    },
    {
      code: "plussalt",
      title: t("plussaltTitle"),
      texts: [t("plussaltText")],
    },
    {
      code: "epaper",
      title: t("epaperTitle"),
      texts: [t("epaperText")],
    },
    {
      code: "shareable",
      title: t("shareableTitle"),
      texts: [
        t("shareableText"),
        t(
          vacantSpacesInFamily === 1
            ? "shareableGroupInfo"
            : "shareableGroupInfoPlural",
          {
            vacantSpacesInFamily,
            familyUrl: `${aidUrl}/aid/familie`,
          },
        ),
      ],
    },
  ];

  const subscriptionProductFeatureCodes = productFeatures.map((pf) => pf.code);
  const lostProductFeatures = lostProductFeaturesTexts.filter((lpf) =>
    subscriptionProductFeatureCodes.includes(lpf.code),
  );

  return (
    <List>
      {lostProductFeatures.map((pf) => (
        <ListItem key={pf.code}>
          <Title>{pf.title}</Title>
          {pf.texts.map((text) => (
            <Text dangerouslySetInnerHTML={{ __html: text }} key={text} />
          ))}
        </ListItem>
      ))}
    </List>
  );
};

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  gap: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const ListItem = styled.li`
  flex: 1;
  min-width: calc(50% - 10px);
`;

const Title = styled.h4`
  margin-bottom: 0px;
`;

const Text = styled.span`
  font-size: 14px;
`;

export default LostProductFeatures;

// "altno" is used to list +alt features such as podcast, sport and alt.no
// "plussalt" still has its separate entry
const messages = {
  digitalTitle: {
    nb: "Plussinnhold",
    nn: "Plussinnhald",
  },
  digitalText: {
    nb: 'Tilgang til å lese alt på <a href="//{{domain}}">{{domain}}</a>.',
    nn: 'Tilgang til å lese alt på <a href="//{{domain}}">{{domain}}</a>.',
  },
  altnoTitle: {
    nb: "Dine +Alt-fordeler",
    nn: "Dine +Alt-fordelar",
  },
  altnoText: {
    nb: 'Tilgang til <a href="https://www.alt.no">ALT</a>, podkast i <a href="https://untold.app/amedia">Untold-appen</a> og direktesendt sport på <a href="https://www.direktesport.no">direktesport.no</a>.',
    nn: 'Tilgang til <a href="https://www.alt.no">ALT</a>, podkast i <a href="https://untold.app/amedia">Untold-appen</a> og direktesend sport på <a href="https://www.direktesport.no">direktesport.no</a>.',
  },
  plussaltTitle: {
    nb: "Nyheter fra hele Norge",
    nn: "Nyheiter frå heile Noreg",
  },
  plussaltText: {
    nb: "Digital tilgang til over 100 aviser og nettsteder.",
    nn: "Digital tilgang til over 100 aviser og nettstader.",
  },
  epaperTitle: {
    nb: "Digital papiravis",
    nn: "Digital papiravis",
  },
  epaperText: {
    nb: "Les papiravisen med eAvis.",
    nn: "Les papiravisen med eAvis.",
  },
  shareableTitle: {
    nb: "Husstandsdeling",
    nn: "Husstandsdeling",
  },
  shareableText: {
    nb: "Kostnadsfri deling av abonnement med andre i din husstand. ",
    nn: "Kostnadsfri deling av abonnement med andre i din husstand. ",
  },
  shareableGroupInfo: {
    nb: 'Du har <a href="//{{familyUrl}}">{{vacantSpacesInFamily}} ledig plass</a>.',
    nn: 'Du har <a href="//{{familyUrl}}">{{vacantSpacesInFamily}} ledig plass</a>.',
  },
  shareableGroupInfoPlural: {
    nb: 'Du har <a href="//{{familyUrl}}">{{vacantSpacesInFamily}} ledige plasser</a>.',
    nn: 'Du har <a href="//{{familyUrl}}">{{vacantSpacesInFamily}} ledige plassar</a>.',
  },
};
