import { InputAddress } from "./addressFieldset";

export const addressErrorMessages = {
  missingStreetName: {
    nb: "Du må oppgi gatenavn",
    nn: "Du må oppgi gatenamn",
  },
  missingStreetNumber: {
    nb: "Du må oppgi gatenummer",
    nn: "Du må oppgi gatenummer",
  },
  invalidStreetNumber: {
    nb: "Ugyldig gatenummer",
    nn: "Ugyldig gatenummer",
  },
  streetNumberNotNumeric: {
    nb: "Oppgang må skrives i eget felt",
    nn: "Oppgang må skrivast i eiget felt",
  },
  streetNameEndsWithNumber: {
    nb: "Gatenavn kan ikke slutte med tall. Fyll ut gatenummer i eget felt.",
    nn: "Gatenamn kan ikkje slutte med tal. Fyll ut gatenummer i eiget felt.",
  },
  missingPostalCode: {
    nb: "Du må oppgi postnummer",
    nn: "Du må oppgi postnummer",
  },
  invalidPostalCode: {
    nb: "Ugyldig postnummer",
    nn: "Ugyldig postnummer",
  },
  missingCity: {
    nb: "Du må oppgi sted",
    nn: "Du må oppgi stad",
  },
  entranceLength: {
    nb: "Ugyldig oppgang",
    nn: "Ugyldig oppgang",
  },
  floorNumberLength: {
    nb: "Ugyldig etasje",
    nn: "Ugyldig etasje",
  },
  apartmentLength: {
    nb: "Ugyldig leilighetsnr.",
    nn: "Ugyldig bustadnummer",
  },
};

export const validateAddress = (fields: InputAddress) => {
  type Field = keyof InputAddress;
  type Error = keyof typeof addressErrorMessages;

  const streetNumberRegex = /^\d{1,5}$/; // must start with number
  const postalCodeRegex = /^\d{4}$/; // e.g. 0854
  const invalidStreetNameRegex = /\d+\s*$/; // cannot end with number
  const invalidEntranceRegex = /^[a-zA-Z0-9æøåÆØÅ]{0,3}$/;
  const floorNumberRegex = /^\d{0,3}$/;
  const apartmentLengthRegex = /^\w{0,6}$/;
  const errors: Array<[Field, Error]> = [];

  /*
   * INFO: For now, the order of these fields should be the same order as they appear in the address form.
   * this is to ensure that when submitting a form with errors, the first input with an error gets focused.
   */

  // Street name cannot be empty
  if (fields.streetName === "") {
    errors.push(["streetName", "missingStreetName"]);
  } else if (invalidStreetNameRegex.exec(fields.streetName)) {
    errors.push(["streetName", "streetNameEndsWithNumber"]);
  }

  // Street number
  if (fields.streetNumber === "") {
    errors.push(["streetNumber", "missingStreetNumber"]);
  } else if (!streetNumberRegex.exec(fields.streetNumber)) {
    errors.push(["streetNumber", "invalidStreetNumber"]);
  } else if (!Number(fields.streetNumber)) {
    errors.push(["streetNumber", "streetNumberNotNumeric"]);
  }

  // entrance can not be longer than 3
  if (!invalidEntranceRegex.exec(fields.entrance)) {
    errors.push(["entrance", "entranceLength"]);
  }

  if (!floorNumberRegex.exec(fields.floor)) {
    errors.push(["floor", "floorNumberLength"]);
  }

  if (!apartmentLengthRegex.exec(fields.apartment)) {
    errors.push(["apartment", "apartmentLength"]);
  }

  // Postal code must be four digits
  if (fields.postalCode === "") {
    errors.push(["postalCode", "missingPostalCode"]);
  } else if (!postalCodeRegex.exec(fields.postalCode)) {
    errors.push(["postalCode", "invalidPostalCode"]);
  }

  // City cannot be empty
  if (fields.city === "") {
    errors.push(["city", "missingCity"]);
  }

  return errors;
};
