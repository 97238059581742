import React from "react";
import dayjs from "dayjs";

import Receipt from "./receipt";

import PageHeader from "@/components/PageHeader";
import { useTranslate } from "@/i18n";
import Address from "@/components/Address";
import type { Address as AddressType } from "@/fetch/mappers/addressMapper";

type Props = {
  address: AddressType;
  fromDate: string;
  toDate: string;
};

const TemporaryAddressChangeReceipt: React.FC<Props> = ({
  address,
  fromDate,
  toDate,
}) => {
  const t = useTranslate(messages);

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Receipt>
        <strong>{t("messageSent")}</strong>
        <p>
          {t("info", {
            to: dayjs(toDate).format("DD.MM.YYYY"),
            from: dayjs(fromDate).format("DD.MM.YYYY"),
            ...(address.streetName && { address: address.streetName }),
          })}
        </p>
        {address ? <Address {...address} /> : "Ukjent"}
        <p>{t("rememberPosten")}</p>
      </Receipt>
    </>
  );
};

export default TemporaryAddressChangeReceipt;

const messages = {
  title: {
    nb: "Jeg skal reise bort",
    nn: "Eg skal reise bort",
  },
  messageSent: {
    nb: "Meldingen er sendt",
    nn: "Meldinga er sendt",
  },
  info: {
    nb: "Fra {{from}} til {{to}} sender vi papiravisen din til:",
    nn: "Frå {{from}} til {{to}} sender vi papiravisa di til:",
  },
  rememberPosten: {
    nb: "Husk å sjekke at adressen også er registrert hos Posten.",
    nn: "Husk å sjekke at adressa også er registrert hos Posten.",
  },
};
