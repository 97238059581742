import { Dayjs } from "dayjs";

import { AddressChange } from "@/fetch/address/getAddressChanges";

export enum ChangeTypes {
  past = "past",
  active = "active",
  future = "future",
}

const getActiveChanges = (changes: AddressChange[], timeNow: Dayjs) =>
  changes
    .filter((change) => change.type !== "DELIVER_PERMANENT")
    .filter((change) => change.startDate.isBefore(timeNow))
    .filter((change) =>
      change.endDate ? change.endDate.isAfter(timeNow) : true,
    );

const getFutureChanges = (changes: AddressChange[], timeNow: Dayjs) =>
  changes.filter((change) => change.startDate.isAfter(timeNow));

const getPastChanges = (changes: AddressChange[], timeNow: Dayjs) =>
  changes.filter((change) => {
    if (change.endDate && change.type !== "DELIVER_PERMANENT") {
      return change.endDate.isBefore(timeNow);
    }
    return (
      change.type === "DELIVER_PERMANENT" && !change.startDate.isAfter(timeNow)
    );
  });

export const groupAddressChange = (
  changes: AddressChange[],
  timeNow: Dayjs,
) => ({
  [ChangeTypes.future]: getFutureChanges(changes, timeNow),
  [ChangeTypes.active]: getActiveChanges(changes, timeNow),
  [ChangeTypes.past]: getPastChanges(changes, timeNow),
});
