import React from "react";
import dayjs from "dayjs";

import Receipt from "./receipt";

import PageHeader from "@/components/PageHeader";
import { useTranslate } from "@/i18n";

type Props = {
  fromDate: string;
  toDate: string;
};

const TemporaryStopReceipt: React.FC<Props> = ({ fromDate, toDate }) => {
  const t = useTranslate(messages);

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Receipt>
        <strong>{t("messageSent")}</strong>
        <p>
          {t("info", {
            to: dayjs(toDate).format("DD.MM.YYYY"),
            from: dayjs(fromDate).format("DD.MM.YYYY"),
          })}
        </p>
        <p>{t("digitalInfo")}</p>
      </Receipt>
    </>
  );
};

export default TemporaryStopReceipt;

const messages = {
  title: {
    nb: "Midlertidig stans",
    nn: "Mellombels stopp",
  },
  messageSent: {
    nb: "Meldingen er sendt",
    nn: "Meldinga er sendt",
  },
  info: {
    nb: "Vi stanser papiravisen din fra {{from}} til {{to}}.",
    nn: "Vi stoppar papiravisa di frå {{from}} til {{to}}.",
  },
  digitalInfo: {
    nb: "Selv om du tar en pause fra papiravisen, har du fortsatt full tilgang.",
    nn: "Sjølv om du tek ei pause frå papiravisa, har du fortsatt full tilgang.",
  },
};
