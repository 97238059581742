import { handleResNotOk } from "../fetcher";
import { errorToString } from "../errors";

import { CallbackType, UpdatePaymentStatus } from "./helpers";

import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { absoluteUrl } from "@/utils/url";

export const startChangeCreditCard = async ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  try {
    const onCompletedRedirectUrl = absoluteUrl(
      routes.callback.path(subscriptionId, CallbackType.CREDIT_CARD),
    );

    const onCancelRedirectUrl = absoluteUrl(routes.error.path());

    const params = new URLSearchParams({
      subscriptionId,
      onCancelRedirectUrl: onCancelRedirectUrl.href,
      onCompletedRedirectUrl: onCompletedRedirectUrl.href,
    });

    const url = `/api/vorwerk/credit_card/start_agreement_url?${params}`;

    const response = await fetch(url, { method: "GET" });

    if (!response.ok) {
      return handleResNotOk(response, "startChangeCreditCard failed.");
    }

    const data = await response.json();

    if (data.url) {
      return window.location.assign(data.url);
    }
    throw new Error("no url in response");
  } catch (error) {
    log.error(`startChangeCreditCard failed.`, error);
  }
};

export const netsEasyResumeReplaceCard = async ({
  infosoftOrderRef,
  subscriptionId,
}: {
  infosoftOrderRef: string;
  subscriptionId: string;
}): Promise<{ status: UpdatePaymentStatus; error?: string }> => {
  try {
    const url = "/api/vorwerk/credit_card/resume_replace_card";

    const fetchOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        infosoftOrderRef,
        subscriptionId,
      }),
    };

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      return handleResNotOk(response);
    }

    return { status: UpdatePaymentStatus.SUCCESS };
  } catch (error) {
    return {
      status: UpdatePaymentStatus.ERROR,
      error: errorToString(error),
    };
  }
};
