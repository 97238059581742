import { useEffect, useState } from "react";

export const useMediaQuery = (mediaQuery: string) => {
  const [matches, setMatches] = useState<boolean>(
    window.matchMedia(mediaQuery).matches,
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    if (mediaQueryList.matches !== matches) {
      setMatches(mediaQueryList.matches);
    }
    const updateMatches = () => setMatches(mediaQueryList.matches);
    mediaQueryList.addEventListener("change", updateMatches);

    return () => {
      mediaQueryList.addEventListener("change", updateMatches);
    };
  }, [mediaQuery, matches]);

  return matches;
};
