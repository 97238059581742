import useSwr from "swr";
import { Invoice } from "@amedia/node-clients-abo";
import dayjs from "dayjs";

import { fetcher } from "../fetcher";
import { FetcherError } from "../errors";

type PaymentHistoryResponse = {
  invoices: Invoice[];
};

export const useGetPaymentHistory = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  const twelweMonthsAgo = dayjs().subtract(12, "month").format("YYYY-MM-DD");
  const toDate = dayjs().format("YYYY-MM-DD");

  const params = new URLSearchParams({
    fromDate: twelweMonthsAgo,
    toDate,
    subscriptionId,
  });

  const url = `/api/vorwerk/payment/invoice/history?${params}`;

  const { data, error } = useSwr<PaymentHistoryResponse, Error | FetcherError>(
    [url, { method: "GET" }],
    fetcher,
  );

  return {
    paymentHistory: data?.invoices,
    ...(error && { error: error }),
  };
};
