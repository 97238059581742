import React, { useContext } from "react";
import { styled } from "styled-components";
import { useOutletContext } from "react-router-dom";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import ExternalLink from "@/components/ExternalLink";
import PlussAltLogo from "@/public/plussalt_black.png";
import Heading from "@/components/Heading";
import colors from "@/theme/colors";
import { AppOutlet } from "@/components/AppRoute/appRoute";
import { useSimpleSubscriptionFetcher } from "@/fetch/useSimpleSubscriptionsFetcher";
import AppContext from "@/contexts/appContext";
import { log } from "@/logging/logger";
import { includesPlussalt } from "@/utils/productFeatures";

const PlussaltAccess: React.FC = () => {
  const t = useTranslate(messages);
  const { participatesInPlussalt } = useContext(AppContext).site;
  const { user } = useOutletContext<AppOutlet>();

  const hasAccess = user.access.length > 0;

  const { simpleSubscriptions, error } = useSimpleSubscriptionFetcher();

  if (error) {
    log.error("Fetching simple subscriptions failed", error);
  }

  if (!simpleSubscriptions || simpleSubscriptions?.length === 0) {
    return null;
  }

  const plussaltSubscriptions = simpleSubscriptions.filter((s) =>
    includesPlussalt(s.productFeatures),
  );

  return participatesInPlussalt && hasAccess && plussaltSubscriptions.length > 0
    ? plussaltSubscriptions.map((s) => (
        <Sheet
          key={s.name}
          $borderTopColor={colors.amediaMagenta}
          data-testid="plussalt-access"
        >
          <LocalHeading>{t("title", { newspaper: s.name })}</LocalHeading>
          <Container>
            <Description>{t("description", { newspaper: s.name })}</Description>
            <Logo src={PlussAltLogo} alt="+Alt logo" />
          </Container>
          <ExternalLink href={`//${s.publicationDomain}/abo`}>
            {t("cta", { newspaper: s.name })}
          </ExternalLink>
        </Sheet>
      ))
    : null;
};

export default PlussaltAccess;

const LocalHeading = styled(Heading)`
  font-weight: bold;
  max-width: 474px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Description = styled.p`
  margin-top: 0;
  max-width: 498px;
`;

const Logo = styled.img`
  height: 55px;
  margin-top: -6px;
`;

const messages = {
  title: {
    nb: "Du har tilgang fordi du har +Alt-abonnement på {{newspaper}}",
  },
  description: {
    nb: "Du har ikke abonnement på denne avisen, men fordi du har +Alt-abonnement via {{newspaper}} har du tilgang til plussartikler og eAvis på denne og mer enn 100 andre aviser.",
  },
  cta: {
    nb: "Gå til min side på {{newspaper}} for å gjøre endringer i ditt abonnement",
  },
};
