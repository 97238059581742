import { FaExternalLinkAlt } from "react-icons/fa";
import { styled } from "styled-components";

import { Sheet } from "@/components/Sheet/index";
import { useTranslate } from "@/i18n";
import AidLogo from "@/components/AidLogo";
import { aidUrl } from "@/routes/external";

const Family = () => {
  const t = useTranslate(messages);

  return (
    <A href={`${aidUrl}/aid/familie`}>
      <Wrapper>
        <LinkText>
          {t("shareMessage")}
          <StyledExternalLink />
        </LinkText>
        <AidLogo height={30} width={30} />
      </Wrapper>
    </A>
  );
};

const A = styled.a`
  display: block;
  color: inherit;
`;

const Wrapper = styled(Sheet)`
  display: flex;
  align-items: center;
`;

const LinkText = styled.span`
  padding-right: 10px;
  flex-grow: 1;
`;

const StyledExternalLink = styled(FaExternalLinkAlt)`
  height: 0.85em;
  width: 0.85em;
  margin: 0 0 0 6px;
`;

export default Family;

const messages = {
  shareMessage: {
    nb: "Del abonnementet med familie i husstanden min",
    nn: "Del abonnementet med familie i husstanden min",
  },
};
