import React from "react";
import { useOutletContext } from "react-router-dom";

import PlussaltAccess from "./plussaltAccess";
import AmediaStaff from "./amediaStaff";
import Family from "./family";

import SubscriptionCard from "@/components/SubscriptionCard";
import { AppOutlet } from "@/components/AppRoute/appRoute";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { includesShareable } from "@/utils/productFeatures";

type Props = {
  subscriptions: Subscription[];
};

const SubscriptionList: React.FC<Props> = ({ subscriptions }) => {
  const { user } = useOutletContext<AppOutlet>();
  const hasAccess = user.access.length > 0;

  return (
    <>
      {subscriptions.length === 0 && hasAccess && <PlussaltAccess />}
      <AmediaStaff hasSubscription={subscriptions.length > 0} />
      {subscriptions.map((sub) => (
        <div key={sub.id}>
          <SubscriptionCard subscription={sub} />
          {includesShareable(sub.product.productFeatures) && <Family />}
        </div>
      ))}
    </>
  );
};

export default SubscriptionList;
