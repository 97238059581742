import React from "react";
import { Root, createRoot } from "react-dom/client";

import App from "./app";

const container = document.getElementById("app");

const renderApp = (root: Root) => {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

if (container) {
  const root = createRoot(container);
  renderApp(root);
}
