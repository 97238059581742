import React from "react";

import { useTranslate } from "@/i18n";

const messages = {
  description: {
    nb: "Ta kontakt med kundesenteret om du ønsker å bytte betalingsmetode.",
    nn: "Ta kontakt med kundesenteret om du ønskjer å byte betalingsmetode.",
  },
};

const CustomerService: React.FC = () => {
  const t = useTranslate(messages);
  return <p data-testid="change-to-customer-service">{t("description")}</p>;
};

export default CustomerService;
