import React, { useContext } from "react";
import { styled } from "styled-components";

import { PrimaryButton } from "../Button";

import { useTranslate } from "@/i18n";
import { pageTitle } from "@/utils/page";
import routes from "@/routes/routes";
import ErrorBlock from "@/components/ErrorBlock";
import AppContext from "@/contexts/appContext";

const TemporarilyUnavailable: React.FC = () => {
  const t = useTranslate(messages);
  const { locale, name } = useContext(AppContext).site;
  pageTitle.set(
    pageTitle.create(name.short, routes.temporarilyUnavailable.title[locale]),
  );

  return (
    <div data-testid="temporarily-unavailable">
      <ErrorBlock title={t("title")}>
        <ErrorText>
          <p>{t("errorMessage")}</p>
          <a href="/">
            <PrimaryButton as="span">{t("goToFrontpage")}</PrimaryButton>
          </a>
        </ErrorText>
      </ErrorBlock>
    </div>
  );
};

export default TemporarilyUnavailable;

const ErrorText = styled.div`
  margin-bottom: 20px;
`;

const messages = {
  title: {
    nb: "Beklager, noe gikk galt",
    nn: "Beklagar, noko gjekk gale",
  },
  goToFrontpage: {
    nb: "Til forsiden",
    nn: "Til framsida",
  },
  errorMessage: {
    nb: "Vi arbeider med å rette feilen, og beklager ulempene den medfører. Vi anbefaler at du prøver igjen senere.",
    nn: "Vi arbeider med å retta feilen, og beklagar ulempene den medfører. Vi anbefaler at du prøver igjen seinare.",
  },
};
