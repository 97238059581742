import React, { useState } from "react";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { styled, css } from "styled-components";

type InputValue = string | number | string[];

export interface RadioButtonProps<T extends InputValue> {
  groupName: string;
  options: Array<RadioButtonProp<T>>;
  selected?: InputValue;
  callBack: (value: T) => void;
  className?: string;
}

export interface RadioButtonProp<T extends InputValue> {
  value: T;
  labelComponent: React.ReactElement;
}

export function RadioButtonGroup<T extends InputValue = InputValue>({
  groupName,
  options,
  selected,
  callBack,
  className,
}: RadioButtonProps<T>) {
  const [checked, setChecked] = useState(selected);
  return (
    <InputSection className={className}>
      {options.map((opt: RadioButtonProp<T>, index: number) => (
        <RadioButtonLabel
          key={`${groupName}-label-${index}`}
          htmlFor={`${groupName}-btn-${index}`}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            callBack(opt.value);
            setChecked(opt.value);
          }}
          checked={checked === opt.value}
        >
          <RadioButtonWrapper>
            <StyledRadioButton
              type="radio"
              name={groupName}
              value={opt.value}
              id={`${groupName}-btn-${index}`}
              key={`${groupName}-btn-${index}`}
            />
            {checked === opt.value ? <CheckedButton /> : <UncheckedButton />}
          </RadioButtonWrapper>
          {opt.labelComponent}
        </RadioButtonLabel>
      ))}
    </InputSection>
  );
}

const StyledRadioButton = styled.input`
  opacity: 0;
  position: absolute;
`;

const InputSection = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  & > :not(:first-child) {
    margin-top: 15px;
  }
`;

const RadioButtonLabel = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  padding: 13px 20px;
  border: 2px solid #e9eaee;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  ${({ checked, theme }) =>
    !checked &&
    `
  &:focus-within {
    border: 2px solid ${theme.primaryColor};
  }`}
  ${({ checked, theme }) =>
    checked &&
    `
    border-color: ${theme.primaryColor};
  `}

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

const buttonCss = css`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.primaryColor};
`;

const CheckedButton = styled(MdRadioButtonChecked)`
  ${buttonCss}
`;

const UncheckedButton = styled(MdRadioButtonUnchecked)`
  ${buttonCss}
`;
