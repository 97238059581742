import React from "react";
import { useOutletContext, Navigate } from "react-router-dom";

import routes from "@/routes/routes";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute";
import {
  PaymentStatusType,
  UpdatePaymentStatus,
} from "@/fetch/payment/helpers";

export const isValidStatus = (str: string): str is UpdatePaymentStatus =>
  Object.values(UpdatePaymentStatus).includes(str as UpdatePaymentStatus);

type Props = {
  type: PaymentStatusType;
  status: UpdatePaymentStatus;
};

const CallbackNavigate: React.FC<Props> = ({ type, status }) => {
  const { subscription } = useOutletContext<SubscriptionOutlet>();

  return (
    <Navigate
      to={routes.payment.path(subscription.id)}
      replace={true}
      state={{ paymentType: type, paymentStatus: status }}
    />
  );
};

export default CallbackNavigate;
