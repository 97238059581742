import { PathReporter } from "io-ts/lib/PathReporter";
import useSwr from "swr";

import { FamiliesSchema, FamilyExternal } from "./external";
import { Family } from "./internal";

import { DecodingError, FetcherError } from "@/fetch/errors";
import { fetchWithExtendedError } from "@/fetch/fetch";

export const MERCURY_URL =
  typeof window === "object"
    ? `${window.location.origin}/api/mercury/v1`
    : "/api/mercury/v1"; // Because of playwright tests env not having window

export const mapFamily = (f: FamilyExternal) => ({
  uuid: f.uuid,
  name: f.name,
  createdAt: new Date(f.created_at),
  type: f.type,
  allotments: f.allotments,
  members: f.members.map((m) => ({
    uuid: m.uuid,
    name: m.name,
    hasAvatar: m.has_avatar,
    avatar: m.avatar,
  })),
});

interface FamiliesFetcherResult {
  families: Family[];
  error?: FetcherError | Error;
}

export const useFamiliesFetcher = (): FamiliesFetcherResult => {
  const url = `${MERCURY_URL}/user/groups`;

  const { data, error } = useSwr<FamilyExternal[], Error>(
    url,
    fetchWithExtendedError(familiesDecoder),
  );

  return {
    families: data ? data.map((s) => mapFamily(s)) : [],
    ...(error && { error }),
  };
};

const familiesDecoder = (value: FamilyExternal[]) => {
  const decodedValue = FamiliesSchema.decode(value);

  if (decodedValue._tag === "Left") {
    const error = new DecodingError("Could nok decode subscription request");
    error.decoderReport = PathReporter.report(decodedValue);
    throw error;
  }

  return decodedValue.right;
};
