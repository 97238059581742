const createPageTitle = (nameShort: string, page: string) =>
  (document.title = `${nameShort} | ${page}`);

const setPageTitle = (title: string) => (document.title = title);

const pageTitle = {
  set: setPageTitle,
  create: createPageTitle,
};

export { pageTitle };
