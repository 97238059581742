import dayjs, { Dayjs, locale as dayjsLocale } from "dayjs";
import * as locale from "dayjs/locale/nb";

export function dateFormat(date: string | Dayjs, format = "D. MMM YYYY") {
  dayjsLocale(locale);
  const dateObj = typeof date === "string" ? dayjs(date) : date;
  return dateObj.isValid() ? dateObj.format(format) : date;
}

export const dateCompare = (
  dateA: string,
  dateB: string,
  direction: "ascending" | "descending" = "ascending",
): number => {
  const a = dayjs(dateA);
  const b = dayjs(dateB);

  if (direction === "ascending") {
    return b.isBefore(a) ? 1 : -1;
  }
  return a.isBefore(b) ? 1 : -1;
};
