import { styled } from "styled-components";

import colors from "@/theme/colors";

//display: block; necessary for when Button is cast to "span" (when inside links <a>)
const Button = styled.button`
  display: block;
  min-width: 70px;
  font-size: 15px;
  background: transparent;
  cursor: pointer;
  color: ${colors.almostBlack};
  font-weight: bold;
  padding: 1em 2.5em;
  text-decoration: none;
  text-align: center;

  border: 3px solid;
  border-color: ${(props) => props.theme.primaryColor ?? colors.almostBlack};

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  &:focus {
    text-decoration: underline;
  }
`;

export const PrimaryButton = styled(Button)`
  &&& {
    color: ${colors.white};
  }

  display: inline-block;
  background-color: ${(props) =>
    props.theme.primaryColor ?? colors.almostBlack};

  &:hover {
    text-decoration: underline;
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${(props) => props.theme.primaryColor ?? colors.almostBlack};
`;

type Props = {
  textAlign?: "left" | "right" | "center";
};

export const TextButton = styled.button<Props>`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
`;

export const ColoredButton = styled(Button)`
  &&& {
    color: ${colors.white};
  }
  border-color: ${(props) => props.color};
  background-color: ${(props) => props.color};
`;

export const SlimPrimaryButton = styled(PrimaryButton)`
  padding: 0.35em 2.2em;
`;

export const SlimSecondaryButton = styled(SecondaryButton)`
  padding: 0.35em 2.2em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;
  gap: 20px;

  @media only screen and (max-width: 610px) {
    flex-direction: column;
    max-width: 270px;

    button {
      width: 100%;
    }
  }
`;
