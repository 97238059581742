import {
  ExpandedContent,
  ExpandedSubscriptionContainer,
} from "./expandedSubscriptionContainer";
import { SubscriptionInfoCard } from "./subscriptionInfoCard";
import { altnoButton } from "./utils";

import { useTranslate } from "@/i18n";

interface AltInfoProps {
  publicationDomain: string;
}

export const AltInfo = ({ publicationDomain }: AltInfoProps) => {
  const t = useTranslate(altInfoText);

  const altContent: ExpandedContent[] = [
    {
      id: "alt-01",
      title: t("altTitlePointOne"),
      content: t("altDescriptionPointOne"),
    },
    {
      id: "alt-02",
      title: t("altTitlePointTwo"),
      content: t("altDescriptionPointTwo"),
      previewImage: true,
    },
    {
      id: "alt-03",
      title: t("altTitlePointThree", { publicationDomain }),
      content: t("altDescriptionPointThree", { publicationDomain }),
    },
  ];

  return (
    <SubscriptionInfoCard
      id="altno"
      title="ALT"
      type="alt"
      description={[t("altDescription")]}
      buttons={altnoButton}
      expandedContent={
        <ExpandedSubscriptionContainer contentList={altContent} type="alt" />
      }
    />
  );
};
export default AltInfo;

const altInfoText = {
  altDescription: {
    nb: "ALT gjør det enklere for deg å holde deg oppdatert! Du velger selv hvilke aviser du vil få oppdateringer fra, som deretter vises i din nyhetsfeed. På denne måten går du aldri glipp av noe! ALT finnes som app for både iOS og Android, og kan brukes rett i nettleseren. ",
    nn: "ALT gjer det enklare for deg å halde deg oppdatert! Du veljer sjølv kva for aviser du vil få oppdateringar frå, som deretter visast i nyhetsfeeden din. Slik går du aldri glipp av noko! ALT finnast som app for både iOS og Android, og kan brukast rett i nettlesaren.",
  },

  altTitlePointOne: {
    nb: "Hvordan bruker jeg ALT?",
    nn: "Korleis brukar eg ALT?",
  },
  altDescriptionPointOne: {
    nb: "Når du logger inn i ALT-appen blir du bedt om å plukke ut hvilke aviser du ønsker å følge. Forsiden i appen vil da oppdateres med alle de siste artiklene fra disse avisene, etterhvert som sakene legges ut. Du kan både legge til og fjerne aviser fra din nyhetsfeed når som helst. ",
    nn: "Når du loggar inn i ALT for fyrste gong blir du bedt om å velje kva for aviser du ønskjer å følgje. Framsida i appen vil da oppdaterast regelmessig med siste nytt frå dei valde avisene. Du kan endra din nyhetsfeed når som helst.",
  },
  altTitlePointTwo: {
    nn: "Oppdag meir!",
    nb: "Oppdag mer!",
  },
  altDescriptionPointTwo: {
    nb: "Under Oppdag-fanen får du et utvalg saker som er godt lest fra resten av landet som vi håper du er interessert i.",
    nn: "Under Oppdag-fanen får du eit utval saker som er godt lest frå resten av landet som vi trur du er interessert i. ",
  },
  altTitlePointThree: {
    nn: "Hvordan får du tilgang? ",
    nb: "Hvordan får du tilgang? ",
  },
  altDescriptionPointThree: {
    nn: "Du logger inn på ALT på samme måte som du logger på {{publicationDomain}} med din aID-bruker. ",
    nb: "Du loggar inn på ALT på same måte som du logger på {{publicationDomain}} med din aID. ",
  },
  helperText: {
    nb: "Last ned appen for iOS eller Android",
    nn: "Last ned appen for iOS eller Android",
  },
};
