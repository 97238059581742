import React, { useContext } from "react";
import { styled } from "styled-components";

import { Sheet } from "@/components/Sheet";
import AmediaInclude from "@/components/AmediaInclude";
import AppContext from "@/contexts/appContext";

const ScrollArticlesSmartEmbed: React.FC = () => {
  const { domain } = useContext(AppContext).site;
  return (
    <Wrapper>
      <AmediaInclude
        manifest="/api/mozart/v1/scrollarticles/manifest"
        param-publication={domain}
        param-shuffle={true}
      />
    </Wrapper>
  );
};
export default ScrollArticlesSmartEmbed;

const Wrapper = styled(Sheet)`
  padding: 0;
`;
