import * as t from "io-ts";

const FamilyUserSchema = t.type({
  uuid: t.string,
  name: t.string,
  has_avatar: t.boolean,
  avatar: t.string,
});

const FamilySchema = t.type({
  uuid: t.string,
  name: t.string,
  created_at: t.string,
  type: t.string,
  allotments: t.number,
  members: t.array(FamilyUserSchema),
});

export const FamiliesSchema = t.array(FamilySchema);
export type FamilyExternal = t.TypeOf<typeof FamilySchema>;
