import { styled } from "styled-components";

import { useTranslate } from "@/i18n";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex.-wrap: wrap;
  justify-content: space-between;
  padding: 0px 16px;
  margin: 0;

  > img {
    flex-gap: 8px;
  }
`;

const Paragraph = styled.p`
  margin: 0px;
  padding: 16px;
  textalign: center;
  color: grey;
`;

interface RelevantTitleProps {
  relevantTitles: string[];
}

export const RelevantTitles = ({ relevantTitles }: RelevantTitleProps) => {
  const t = useTranslate(infoText);

  const getLogoUrl = (publicationDomain: string) => {
    return `https://g.acdn.no/local/v3/publications/${publicationDomain}/gfx/small-positive.svg`;
  };

  const logos = relevantTitles.map((pb) => getLogoUrl(pb));

  return (
    <>
      <Container>
        {logos.map((lg) => (
          <img key={lg} src={lg} alt="" width={120} height={50} />
        ))}
      </Container>
      <Paragraph>
        {t("title")}
        <a
          href="https://www.plussalt.no"
          style={{ marginLeft: 4, color: "grey" }}
        >
          {t("alleAviserPlussAlt")}
        </a>
      </Paragraph>
    </>
  );
};
export default RelevantTitles;

const infoText = {
  title: {
    nb: "Andre liker å lese disse avisene, men du har også tilgang til over 100 flere.",
    nn: "Andre liker å lese disse avisene, men du har også tilgang til over 100 flere.",
  },
  alleAviserPlussAlt: {
    nb: "Se alle avisene på plussalt.no",
    nn: "Se alle avisene på plussalt.no",
  },
};
