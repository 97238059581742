import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { styled } from "styled-components";

type Props = {
  href: string;
  icon?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const ExternalLink = ({ children, icon = true, ...rest }: Props) => (
  <a {...rest}>
    {children}
    {icon && <Icon />}
  </a>
);

const Icon = styled(FaExternalLinkAlt)`
  margin: 0 0 0 6px;
  height: 0.85em;
  width: 0.85em;
`;

export default ExternalLink;
