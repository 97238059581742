import { ReactNode, useContext, useRef } from "react";

import AppContext from "@/contexts/appContext";
import routes from "@/routes/routes";
import { autoInvoiceClientUrl } from "@/utils/app";
import { absoluteUrl } from "@/utils/url";
import { CallbackType } from "@/fetch/payment/helpers";

const Input = (props: { name: string; value: string | undefined }) => {
  return (
    <input
      type="hidden"
      name={props.name}
      id={props.name}
      value={props.value}
    />
  );
};

export const StartAutoInvoiceForm = ({
  subscriptionId,
  kidNumber,
  submitButton,
}: {
  subscriptionId: string;
  kidNumber: string;
  submitButton: (params: { onSubmit: () => void }) => ReactNode;
}) => {
  const siteConfig = useContext(AppContext).site;

  const companyName = siteConfig.subscriptionSystem.titleCode;
  const companyAccountNo =
    siteConfig.subscriptionSystem.netsParams.accountNumber;
  const returnUrl = absoluteUrl(
    routes.callback.path(subscriptionId, CallbackType.AUTO_INVOICE),
  ).toString();

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <form action={autoInvoiceClientUrl} method="post" ref={formRef}>
      <Input name="companyName" value={companyName} />
      <Input name="companyAccountNo" value={companyAccountNo} />
      <Input name="kid" value={kidNumber} />
      <Input name="returnUrl" value={returnUrl} />
      <Input name="amountLimit" value="5000" />
      <Input name="notificationDisabled" value="true" />

      {submitButton({ onSubmit: () => formRef.current?.submit() })}
    </form>
  );
};
