import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

import { PrimaryButton } from "@/components/Button";
import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";

const Receipt: React.FC<PropsWithChildren> = ({ children }) => {
  const t = useTranslate(messages);
  return (
    <>
      <Sheet>
        {children}
        <MyPageLinkWrapper as="span">
          <Link to={routes.myPage.path()}>{t("overviewLink")}</Link>
        </MyPageLinkWrapper>
      </Sheet>
    </>
  );
};

const MyPageLinkWrapper = styled(PrimaryButton)`
  display: inline-block;
  margin: 15px 0;
`;

const messages = {
  overviewLink: {
    nb: "Min side",
    nn: "Mi side",
  },
};

export default Receipt;
