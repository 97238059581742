type Params = { [key: string]: string };

const removeEmptyParams = (params: Params): Params => {
  const filtered = Object.entries(params).filter(([_key, value]) => !!value);
  return Object.fromEntries(filtered);
};

const withParams = (url: URL, params: Params) => {
  Object.entries(removeEmptyParams(params)).forEach(([k, v]) => {
    url.searchParams.set(k, v);
  });
  return url;
};

export const absoluteUrl = (path: string, params?: Params) => {
  const url = new URL(`${window.location.origin}${path}`);
  return params ? withParams(url, params) : url;
};
