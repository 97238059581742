import React, { useContext } from "react";
import { styled } from "styled-components";

import PrioritizedActions from "./components/prioritizedActions/prioritizedActions";
import SubscriptionList from "./components/subscriptionList";
import User from "./components/user";

import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import Adplogger2Wrapper from "@/components/Adplogger2/Adplogger2Wrapper";

const MyPage: React.FC = () => {
  const t = useTranslate(messages);
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const subscriptions = context.subscriptions.data;
  pageTitle.set(pageTitle.create(name.short, routes.myPage.title[locale]));

  const showBuySubscription = !!(subscriptions && subscriptions.length === 0);

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <PrioritizedActions />
      <User />
      {context.subscriptions.data && (
        <SubscriptionList subscriptions={context.subscriptions.data} />
      )}
      {context.subscriptions.error && (
        <Sheet data-testid="subscription-error">
          <p>{t("error")}</p>
        </Sheet>
      )}
      {showBuySubscription && (
        <>
          <A
            href={`${window.location.origin}/tilbud/utvalgt`}
            data-testid="buy-subscription"
          >
            <Sheet>{t("buy")}</Sheet>
          </A>
          <Adplogger2Wrapper
            adpType="activate button"
            name="activate-subscription"
          >
            <A
              href={`${window.location.origin}/tilgang/aktiver/abonnent`}
              data-testid="activate-subscription"
              data-adp-clicklabel="activate-subscription"
              data-adp-clickvalue="yes"
            >
              <Sheet>{t("activate")}</Sheet>
            </A>
          </Adplogger2Wrapper>
        </>
      )}
    </>
  );
};

const A = styled.a`
  display: block;
  color: inherit;
`;

const messages = {
  title: {
    nb: "Min side",
    nn: "Mi side",
  },
  buy: {
    nb: "Kjøp abonnement",
    nn: "Kjøp abonnement",
  },
  error: {
    nb: "Greide ikke hente abonnementene dine.",
    nn: "Greide ikkje hente abonnementa dine",
  },
  activate: {
    nb: "Har du abonnement? Aktiver tilgang",
    nn: "Har du allereie abonnement? Stadfest tilgang",
  },
};

export default MyPage;
