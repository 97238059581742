import React from "react";
import { styled } from "styled-components";
import { useLocation } from "react-router-dom";

import { useTranslate } from "@/i18n";
import { Sheet } from "@/components/Sheet";
import { FailureBox, SuccessBox } from "@/components/InfoBox";
import {
  PaymentStatusType,
  UpdatePaymentStatus,
} from "@/fetch/payment/helpers";

const SigningStatus: React.FC = () => {
  const t = useTranslate(messages);
  const { state } = useLocation();

  const paymentType: PaymentStatusType | undefined = state?.paymentType;
  const paymentStatus: UpdatePaymentStatus | undefined = state?.paymentStatus;

  const getTexts = (success: boolean) => ({
    autoInvoice: {
      title: success ? t("autoInvoiceSuccessTitle") : t("failureTitle"),
      msg: success ? t("autoInvoiceSuccess") : t("autoInvoiceFailure"),
    },
    replaceCard: {
      title: success ? t("replaceCardSuccessTitle") : t("failureTitle"),
      msg: success ? t("replaceCardSuccess") : t("replaceCardDeclined"),
    },
  });

  if (paymentType && paymentStatus) {
    const texts = getTexts(paymentStatus === UpdatePaymentStatus.SUCCESS)[
      paymentType
    ];

    return (
      <StatusSheet>
        {paymentStatus === UpdatePaymentStatus.SUCCESS ? (
          <SuccessBox title={texts.title}>{texts.msg}</SuccessBox>
        ) : (
          <FailureBox title={texts.title}>{texts.msg}</FailureBox>
        )}
      </StatusSheet>
    );
  }

  return null;
};

const StatusSheet = styled(Sheet)`
  padding: 0;
`;

export default SigningStatus;

const messages = {
  autoInvoiceSuccessTitle: {
    nb: "AvtaleGiro registrert",
    nn: "AvtaleGiro registrert",
  },
  autoInvoiceSuccess: {
    nb: "Ny AvtaleGiro registrert. Det vil ta opp til en dag før din nye avtale er registrert i våre systemer.",
    nn: "Ny AvtaleGiro registrert. Det vil ta opp til ein dag før den nye avtalen din er registrert i våre system.",
  },
  autoInvoiceFailure: {
    nb: "Noe feilet ved registrering av ny AvtaleGiro. Prøv igjen eller ta kontakt med kundeservice.",
    nn: "Noko feila ved registrering av ny AvtaleGiro. Prøv igjen eller ta kontakt med kundeservice.",
  },
  replaceCardSuccessTitle: {
    nb: "Nytt betalingskort registrert.",
    nn: "Nytt betalingskort registrert.",
  },
  replaceCardDeclinedTitle: {
    nb: "Kortet ble ikke endret.",
    nn: "Kortet vart ikkje endra.",
  },
  replaceCardSuccess: {
    nb: "Det kan ta litt tid før det nye kortet vises.",
    nn: "Det kan ta litt tid før det nye kortet blir vist.",
  },
  replaceCardDeclined: {
    nb: "Transaksjonen kunne ikke gjennomføres.",
    nn: "Transaksjonen kunne ikkje gjennomførast.",
  },
  failureTitle: {
    nb: "Noe gikk galt",
    nn: "Noko gjekk gale",
  },
};
