import React, { PropsWithChildren } from "react";
import { styled } from "styled-components";

import colors from "@/theme/colors";

type Props = PropsWithChildren & {
  href: string;
};

const LinkButton: React.FC<Props> = ({ href, children }) => (
  <StyledLink href={href} className="link-button">
    {children}
  </StyledLink>
);

const StyledLink = styled.a`
  display: inline-block;
  background: ${(props) => props.theme.primaryColor ?? colors.almostBlack};
  color: ${colors.white};
  min-width: 70px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  padding: 1em 2.5em;
  text-decoration: none;
  text-align: center;
  border: 3px solid;
  border-color: ${(props) => props.theme.primaryColor ?? colors.almostBlack};

  &:focus,
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkButton;
