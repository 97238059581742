import useSwr from "swr";
import { Invoice } from "@amedia/node-clients-abo";

import { fetcher } from "../fetcher";
import { FetcherError } from "../errors";

type NextInvoiceResponse = {
  invoice?: Invoice;
};

export const useGetNextInvoice = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  const params = new URLSearchParams({ subscriptionId });

  const url = `/api/vorwerk/payment/invoice/next?${params}`;

  const { data, error, isLoading } = useSwr<
    NextInvoiceResponse,
    Error | FetcherError
  >([url, { method: "GET" }], fetcher);

  return {
    isLoading,
    data: {
      invoice: data?.invoice ?? undefined,
    },
    ...(error && { error }),
  };
};
