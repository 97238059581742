import { styled } from "styled-components";

import colors from "@/theme/colors";

export const Option = styled.option``;

type SelectProps = {
  error?: boolean;
};

export const Select = styled.select<SelectProps>`
  display: block;
  border: 2px solid
    ${({ error }) => (error ? colors.errorRedDark : colors.inactiveGray)};
  background-color: ${colors.white};
  font: inherit;
  height: 2.5em;
  width: 100%;
  outline: none;

  &:focus {
    border-color: ${colors.almostBlack};
  }
`;
