import React from "react";
import { styled } from "styled-components";

import { useTranslate } from "@/i18n";
import { Family } from "@/services/mercury/families/internal";
import { aidUrl } from "@/routes/external";

type Props = {
  family: Family;
  filterUserUUID: string;
};

const FamilyInfo: React.FC<Props> = ({ family, filterUserUUID }) => {
  const t = useTranslate(messages);
  const availableAllotments = family.allotments - family.members.length;

  const filteredFamily = {
    ...family,
    members: family.members.filter((m) => m.uuid !== filterUserUUID),
  };

  return (
    <InfoBox>
      {filteredFamily.members
        .filter((member) =>
          filterUserUUID ? member.uuid !== filterUserUUID : true,
        )
        .map((member) => (
          <FamilyMember key={member.uuid}>
            {member.hasAvatar && (
              <Avatar src={member.avatar} alt="Profilbilde" />
            )}
            {!member.hasAvatar && (
              <MissingAvatar
                viewBox="0 0 38 38"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38ZM23.9139 14.0864C23.9139 16.8012 21.715 19.0002 19.0002 19.0002C16.2853 19.0002 14.0864 16.8012 14.0864 14.0864C14.0864 11.3715 16.2853 9.17256 19.0002 9.17256C21.715 9.17256 23.9139 11.3715 23.9139 14.0864ZM9.17256 26.3708C9.17256 23.1032 15.7202 21.457 19.0002 21.457C22.2801 21.457 28.8277 23.1032 28.8277 26.3708V28.8277H9.17256V26.3708Z"
                />
              </MissingAvatar>
            )}
            <MemberName>{member.name}</MemberName>
          </FamilyMember>
        ))}
      {availableAllotments > 0 && (
        <Allotments
          dangerouslySetInnerHTML={{
            __html: t("allotmentsInfo", {
              availableAllotments,
              familyUrl: `${aidUrl}/aid/familie/vis/${filteredFamily.uuid}`,
            }),
          }}
        />
      )}
    </InfoBox>
  );
};

const InfoBox = styled.div``;

const FamilyMember = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 38px;
`;

const Avatar = styled.img`
  margin-right: 10px;
  border-radius: 200%;
  width: 38px;
  height: 38px;
  overflow: hidden;
`;

const MissingAvatar = styled.svg`
  margin-right: 10px;
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  fill: ${(props) => props.theme.primaryColor};
`;

const MemberName = styled.p`
  font-weight: bold;
`;

const Allotments = styled.p``;

export default FamilyInfo;

const messages = {
  allotmentsInfo: {
    nb: '+ {{availableAllotments}} ledige plasser i din <a href="{{familyUrl}}">aID Familie</a>',
    nn: '+ {{availableAllotments}} ledige plassar i din <a href="{{familyUrl}}">aID Familie</a>',
  },
};
