import i18next, {
  init as i18nextInit,
  createInstance as i18nextCreateInstance,
  TFunction,
} from "i18next";
import { useCallback, useMemo } from "react";

import {
  Languages,
  TranslationFunction,
  TranslationOptions,
  type TranslationMessages,
} from "./types";

const i18nOptions = {
  debug: false,
  defaultNS: "translations",
  fallbackLng: [Languages.nb, Languages.nn],
  interpolation: {
    escapeValue: false, // not needed for React
  },
  // have a common namespace used around the full app
  ns: ["translations"],
  resources: {},
  keySeparator: false as const,
  nsSeparator: false as const,
};

export const i18nitClient = (lng: string): Promise<TFunction> =>
  i18nextInit({
    ...i18nOptions,
    lng,
  });

export const isI18nInitialized = () => i18next.isInitialized;

export const getI18nLanguage = () => i18next.language;

export const toResources = (messages: TranslationMessages) => {
  const resources: Record<string, { translations: Record<string, string> }> =
    {};
  Object.keys(messages).forEach((key) => {
    const translation = messages[key];
    Object.keys(translation).forEach((l) => {
      const language = l === Languages.nn ? Languages.nn : Languages.nb;
      if (resources[language] === undefined) {
        resources[language] = {
          translations: {},
        };
      }

      const text = translation[language || Languages.nb];
      if (!text) {
        console.error("Missing text", { key, language });
        return;
      }

      resources[language].translations[key] = text;
    });
  });
  return resources;
};

export const useTranslate = <T extends TranslationMessages>(
  messages: T,
): TranslationFunction<T> => {
  const i18nInstance = useMemo(() => {
    const instance = i18nextCreateInstance({
      ...i18nOptions, // default config in case i18n wasn't properly initialized
      ...i18next.options,
      initImmediate: true,
      resources: toResources(messages),
      lng: getI18nLanguage(),
    });
    instance.init();
    return instance;
  }, [messages]);

  return useCallback(
    (key: Extract<keyof T, string>, options?: TranslationOptions) =>
      options ? i18nInstance.t(key, options) : i18nInstance.t(key),
    [i18nInstance],
  );
};

// Reexport for convenience
export * from "./types";
