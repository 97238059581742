import React, { PropsWithChildren, useContext, useState } from "react";
import {
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

import CancelSubscriptionInfo from "./components/cancelSubscriptionInfo";
import CancelFamilyInfo from "./components/cancelFamilyInfo";
import CancelReason from "./components/cancelReason";
import CancelConfirmation, {
  ConfirmCancelProps,
} from "./components/cancelConfirmation";
import CancelUnavailable from "./components/cancelUnavailable";

import AppContext from "@/contexts/appContext";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import { useFamiliesFetcher } from "@/services/mercury/families/families";
import { log } from "@/logging/logger";
import ScrollArticlesSmartEmbed from "@/components/ScrollArticlesSmartEmbed";
import BreadCrumbs from "@/components/Breadcrumbs";
import Adplogger2Wrapper from "@/components/Adplogger2/Adplogger2Wrapper";

export enum CancelStage {
  INFO = "info",
  FAMILY = "familie",
  REASON = "skjema",
  CONFIRMATION = "bekreft",
  UNAVAILABLE = "kundesenter",
}

type Params = {
  subscriptionId: string;
  cancelStage: CancelStage;
};

type ConfirmCancelData = ConfirmCancelProps & { reasonText: string };

const CancelSubscriptionPage: React.FC = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { locale, name } = appContext.site;
  const { cancelStage } = useParams<Params>();
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const [cancelSubValues, setCancelSubValues] = useState<ConfirmCancelData>();
  const title = routes.cancelSubscription.title[locale];

  pageTitle.set(pageTitle.create(name.short, title));

  const { families, error } = useFamiliesFetcher();

  if (error) {
    log.error("Failed to fetch families", error);
  }

  const isCancellable =
    !subscription.pendingCancellationDate && subscription.canCancel;

  if (!isCancellable && cancelStage !== CancelStage.UNAVAILABLE) {
    if (subscription.pendingCancellationDate) {
      log.info(
        `Subscription (${subscription.id}) is already cancelled. 
         Pending cancellation date: ${subscription.pendingCancellationDate}`,
      );
    } else {
      log.error(
        `Subscription (${subscription.id}) is not cancellable. 
           CancelStage: ${cancelStage}.`,
      );
    }
    return <Navigate to={routes.myPage.path()} />;
  }

  const goToNextStep = (cancelData?: ConfirmCancelData) => {
    if (cancelData) {
      setCancelSubValues(cancelData);
    }
    const getRoute = (nextStep: CancelStage) =>
      routes.cancelSubscription.path(subscription.id, nextStep);

    const hasFamily = families.find((f) => f.members.length > 1);

    const nextStep: Record<string, string> = {
      [CancelStage.INFO]: hasFamily
        ? getRoute(CancelStage.FAMILY)
        : getRoute(CancelStage.REASON),
      [CancelStage.FAMILY]: getRoute(CancelStage.REASON),
      [CancelStage.REASON]: getRoute(CancelStage.CONFIRMATION),
    };

    navigate(nextStep[cancelStage as CancelStage]);
  };

  type CancelProps = PropsWithChildren & {
    stage: string;
    showScrollArticles?: boolean;
    cancelData?: ConfirmCancelData;
  };

  const CancelStageWrapper: React.FC<CancelProps> = ({
    children,
    stage,
    showScrollArticles = true,
    cancelData,
  }) => (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      <Adplogger2Wrapper
        adpType="View"
        name={`Oppsigelse: ${stage}`}
        data={cancelData}
      >
        {children}
      </Adplogger2Wrapper>
      {showScrollArticles && <ScrollArticlesSmartEmbed />}
    </>
  );

  if (cancelStage === CancelStage.UNAVAILABLE) {
    return (
      <CancelStageWrapper
        stage={`-1 - ${cancelStage}`}
        showScrollArticles={false}
      >
        <CancelUnavailable />
      </CancelStageWrapper>
    );
  }

  if (cancelStage === CancelStage.INFO) {
    return (
      <CancelStageWrapper stage={`1 - ${cancelStage}`}>
        <CancelSubscriptionInfo families={families} handleNext={goToNextStep} />
      </CancelStageWrapper>
    );
  }

  if (cancelStage === CancelStage.FAMILY) {
    return (
      <CancelStageWrapper stage={`2 - ${cancelStage}`}>
        <CancelFamilyInfo families={families} handleNext={goToNextStep} />
      </CancelStageWrapper>
    );
  }

  if (cancelStage === CancelStage.REASON) {
    return (
      <CancelStageWrapper stage={`3 - ${cancelStage}`}>
        <CancelReason handleNext={goToNextStep} />
      </CancelStageWrapper>
    );
  }

  if (cancelStage === CancelStage.CONFIRMATION && cancelSubValues) {
    return (
      <CancelStageWrapper
        stage={`4 - ${cancelStage}`}
        cancelData={cancelSubValues}
      >
        <CancelConfirmation {...cancelSubValues} />
      </CancelStageWrapper>
    );
  }

  // Dont log error if users reload on "bekfreft" stage of cancel (cancelSubValues will be empty)
  if (cancelStage !== CancelStage.CONFIRMATION) {
    log.error(`Unexpected cancelStage: ${cancelStage}`);
  }

  return (
    <Navigate
      to={routes.cancelSubscription.path(subscription.id, CancelStage.INFO)}
    />
  );
};

export default CancelSubscriptionPage;
