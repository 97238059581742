import React from "react";
import { styled } from "styled-components";

import { Address as AddressType } from "@/fetch/mappers/addressMapper";
import { useTranslate } from "@/i18n";

const stringIsNotEmpty = (str?: string): str is string =>
  typeof str === "string" && str.length > 0;

const Address: React.FC<AddressType> = (props) => {
  const t = useTranslate(messages);

  const entrance = stringIsNotEmpty(props.entrance)
    ? t("entrance", { entrance: props.entrance })
    : undefined;

  const floor = stringIsNotEmpty(props.floor)
    ? t("floor", { floor: props.floor })
    : undefined;

  const apartment = stringIsNotEmpty(props.apartment)
    ? t("apartment", { apartment: props.apartment })
    : undefined;

  const apartmentInfoString = [entrance, floor, apartment]
    .filter((elm) => stringIsNotEmpty(elm))
    .join(", ");

  return (
    <Wrapper>
      {props.careOf && props.careOf !== "" && (
        <div>{`${t("careOf")} ${props.careOf}`}</div>
      )}
      <div>{`${props.streetName} ${props.streetNumber}`}</div>
      <div>{apartmentInfoString}</div>
      <div>
        {props.postalCode}, {props.city}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: left;
`;

export default Address;

const messages = {
  entrance: {
    nb: "Oppgang {{entrance}}",
  },
  floor: {
    nb: "{{floor}}. Etasje",
  },
  apartment: {
    nb: "Leilighet {{apartment}}",
  },
  careOf: {
    nb: "c/o",
  },
};
