import useSwr from "swr";

import { fetcher } from "../fetcher";
import { FetcherError } from "../errors";

export enum SubscriptionChangeType {
  Stop = "STOP", // subsription has been cancelled
  Change = "CHANGE", // delivery changes etc
}

export type SubscriptionChange = {
  id: string;
  type: SubscriptionChangeType;
  startDate: string;
  planId?: string;
  endDate?: string | null;
  isDeletable: boolean;
};

type Props = {
  subscriptionId: string;
};

interface SubscriptionChangesResult {
  data: {
    subscriptionId: string;
    changes: SubscriptionChange[];
  };
  error?: FetcherError | Error;
}

export const useSubscriptionChanges = ({
  subscriptionId,
}: Props): SubscriptionChangesResult => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/change`;

  const fetchOptions = { method: "GET" };

  const { data, error } = useSwr<
    { changes: SubscriptionChange[] },
    Error | FetcherError
  >([url, fetchOptions], fetcher);

  return {
    data: { subscriptionId, changes: data?.changes ?? [] },
    ...(error && { error }),
  };
};

type CanCancelCancellationResponse = {
  stopChange?: SubscriptionChange;
  error?: FetcherError | Error;
};

export const useCanCancelCancellation = ({
  subscriptionId,
}: {
  subscriptionId: string;
}): CanCancelCancellationResponse => {
  const { data, error } = useSubscriptionChanges({ subscriptionId });

  const stopChange = data.changes.find(
    (change) =>
      change.isDeletable && change.type === SubscriptionChangeType.Stop,
  );

  return error ? { error } : { stopChange: stopChange };
};
