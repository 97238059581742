import {
  ExpandedContent,
  ExpandedSubscriptionContainer,
} from "./expandedSubscriptionContainer";
import { SubscriptionInfoCard } from "./subscriptionInfoCard";

import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useTranslate } from "@/i18n";

interface DirektesportInfoProps {
  publicationDomain: string;
}

export const DirektesportInfo = ({
  publicationDomain,
}: DirektesportInfoProps) => {
  const t = useTranslate(direktesportText);
  const isMobileDevice = useMediaQuery("(max-width: 680px)");

  const direktesportButtons = [
    {
      id: "ios",
      label: isMobileDevice ? t("ios") : t("downloadIos"),
      href: "https://apps.apple.com/no/app/direktesport/id1493982437",
    },
    {
      id: "android",
      label: isMobileDevice ? t("android") : t("downloadAndroid"),
      href: "https://play.google.com/store/apps/details?id=no.amedia.direktesport&pcampaignid=web_share",
    },
  ];

  const direktesportContent: ExpandedContent[] = [
    {
      id: "direktesport-01",
      title: t("direktesportTitlePointOne"),
      content: t("direktesportDescriptionPointOne"),
    },
    {
      id: "direktesport-02",
      title: t("direktesportTitlePointTwo"),
      content: t("direktesportDescriptionPointTwo"),
      previewImage: true,
    },
    {
      id: "direktesport-03",
      title: t("direktesportTitlePointThree"),
      content: t("direktesportDescriptionPointThree", { publicationDomain }),
    },
  ];

  return (
    <SubscriptionInfoCard
      id="direktesport"
      type="direktesport"
      title={t("title", { publicationDomain })}
      description={[t("direktesportDescription")]}
      buttons={direktesportButtons}
      helperText={t("helperText")}
      expandedContent={
        <ExpandedSubscriptionContainer
          contentList={direktesportContent}
          type="direktesport"
        />
      }
    />
  );
};
export default DirektesportInfo;

const direktesportText = {
  title: {
    nn: "Du har Direktesport gjennom ditt abonnement på {{publicationDomain}}",
    nb: "Du har Direktesport gjennom abonnementet ditt på {{publicationDomain}}",
  },
  direktesportDescription: {
    nb: "Med Direktesport ser du direktesendt idrett på både lokalt og nasjonalt nivå. Direktesport finnes som app for telefon, nettbrett og SmartTV. ",
    nn: "Med Direktesport ser du direktesendt idrett på både lokalt og nasjonalt nivå. Direktesport finst som app for telefon, nettbrett og SmartTV. ",
  },
  direktesportTitlePointOne: {
    nb: "Hvilke idretter har vi?",
    nn: "Kva for idretter kan du sjå? ",
  },
  direktesportDescriptionPointOne: {
    nb: "Vi viser fotball, ishockey, håndball, poker, trav, motorsport, sykkel, Functional fitness og Crossfit, kampsport, gym og turn, svømming og vintersport.",
    nn: "På Direktesport sendast fotball, ishockey, handball, poker, trav, motorsport, sykkel, Functional fitness og Crossfit, kampsport, gym og turn, svømming og vintersport. ",
  },
  direktesportTitlePointTwo: {
    nn: "Fotball",
    nb: "Fotball",
  },
  direktesportDescriptionPointTwo: {
    nb: "Vi viser alt fra OBOS-ligaen, PostNord-ligaen og Norsk Tipping-ligaen, og utvalgte kamper fra Eliteserien, i tillegg til lavere divisjoner. ",
    nn: "Du kan sjå alt frå OBOS-ligaen, PostNord-ligaen og Norsk Tipping-ligaen, og utvalde kampar frå Eliteserien, i tillegg til lågere divisjonar. . ",
  },
  direktesportTitlePointThree: {
    nb: "Hvordan får jeg tilgang?",
    nn: "Korleis får du tilgang? ",
  },
  direktesportDescriptionPointThree: {
    nb: "Du logger på Direktesport på samme måte som du logger på {{publicationDomain}} med din aID-bruker. ",
    nn: "Du logger på Direktesport på same måte som du loggar på {{publicationDomain}} aIDen din. ",
  },
  helperText: {
    nb: "Last ned appen for iOS eller Android",
    nn: "Last ned appen for iOS eller Android",
  },
  ios: {
    nb: "iOS",
    nn: "iOS",
  },
  android: {
    nb: "Android",
    nn: "Android",
  },
  downloadIos: {
    nb: "Last ned for iOS",
    nn: "Last ned for iOS",
  },
  downloadAndroid: {
    nb: "Last ned for Android",
    nn: "Last ned for Android",
  },
};
