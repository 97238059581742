import React from "react";

type Props = {
  manifest: string;
} & { [key: string]: string };

const AmediaInclude: React.FC<Props> = ({ manifest, ...dynamicProps }) => (
  <amedia-include manifest={manifest} {...dynamicProps} lazy={false} />
);

export default AmediaInclude;
