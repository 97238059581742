import { styled } from "styled-components";

import { SubscriptionType } from "./expandedSubscriptionContainer";
import { generateRandomCovers } from "./utils";
import { untoldCovers } from "./untoldCovers";

const PreviewContainer = styled.div<{ type: SubscriptionType }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-height: 140px;
  max-width: 190px;
  gap: 24px;

  & img {
    margin-top: 8px;
    border-radius: 8px;
    object-fit: cover;
  }

  @media (max-width: 680px) {
    max-height: 80px;
    max-width: 80px;
    gap: 8px;
  }
`;

interface PreviewCoversProps {
  type: SubscriptionType;
}

export const PreviewCovers = ({ type }: PreviewCoversProps) => {
  const direkesportCovers = [
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/direktesport/eliteserien.png",
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/direktesport/obosligaen.png",
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/direktesport/postnordligaen.png",
  ];

  const altCovers = [
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/alt/alt_01.png",
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/alt/alt_02.png",
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/alt/alt_03.png",
  ];

  const hundrePlussCovers = [
    "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/plussalt/plussalt_oversiktskart.png",
  ];

  const covers = {
    direktesport: direkesportCovers,
    alt: altCovers,
    hundrePlussAviser: hundrePlussCovers,
  };

  const generatePreviewCovers =
    type === "podcast" ? generateRandomCovers(3, untoldCovers) : covers[type];

  return (
    <PreviewContainer type={type}>
      {generatePreviewCovers.map((src) => (
        <img
          key={`${type}covers-${src}`}
          src={src}
          alt=""
          height="100%"
          width="100%"
          loading="lazy"
        />
      ))}
    </PreviewContainer>
  );
};

export default PreviewCovers;
