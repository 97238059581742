export const aidUrl = (() => {
  const host = window.location.host;

  if (host.endsWith(".api.no")) {
    const urlParts = host.split(".");
    const env = urlParts[urlParts.length - 3];

    return env === "localhost"
      ? "https://www.aid.no.localhost.api.no"
      : `https://www-${env}.aid.no`;
  }
  return "https://www.aid.no";
})();
