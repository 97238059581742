import React from "react";

type Props = {
  height: number;
  width: number;
};

const AidLogo: React.FC<Props> = ({ height, width }) => (
  <div style={{ height: `${height}px`, width: `${width}px` }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.41 60.83">
      <path
        strokeDasharray="140"
        fill="#ec008b"
        strokeWidth="0"
        d="M12.35 35.88l10.17-1.51c2.35-.34 3.11-1.51 3.11-2.94 0-2.94-2.27-5.38-7-5.38-4.87 0-7.56 3.11-7.9 6.72l-9.92-2.1c.7-6.47 6.67-13.62 17.76-13.62 13.11 0 18 7.39 18 15.71v26.9h-9.69a27.38 27.38 0 0 1-.5-5c-2.1 3.28-6 6.13-12.18 6.13-8.82 0-14.2-6-14.2-12.44C0 41 5.46 36.89 12.35 35.88zm13.28 7.06v-1.85l-9.33 1.46c-2.86.42-5.13 2-5.13 5.21 0 2.44 1.76 4.79 5.38 4.79 4.71 0 9.08-2.3 9.08-9.61z"
      />
      <path
        strokeDasharray="916"
        fill="#1e282d"
        strokeWidth="0"
        d="M43.27 59.66V0h10.27v59.66zM60.26 59.66V0h21.12c16.24 0 29 10.77 29 30s-13 29.7-29.11 29.7zM81 50.4c10.35 0 18.93-6.65 18.93-20.45 0-14-8.5-20.7-18.85-20.7H70.36V50.4z"
      />
    </svg>
  </div>
);

export default AidLogo;
