import useSwr from "swr";
import { PaymentMethod } from "@amedia/node-clients-abo";

import { fetcher } from "../fetcher";
import { FetcherError } from "../errors";

type PaymentMethodResponse = {
  paymentMethod: PaymentMethod;
};

export const useGetPaymentMethodById = ({ id }: { id?: string }) => {
  const url = id ? `/api/vorwerk/payment/method/${id}` : undefined;

  const { data, error } = useSwr<PaymentMethodResponse, Error | FetcherError>(
    [url, { method: "GET" }],
    fetcher,
  );

  return {
    paymentMethod: data?.paymentMethod,
    ...(error && { error }),
  };
};
