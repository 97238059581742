import { List } from "react-content-loader";
import {
  PaymentMethod,
  Invoice as InvoiceType,
} from "@amedia/node-clients-abo";

import { StartAutoInvoiceForm } from "./paymentMethods/startAutoInvoiceForm";

import { TextButton } from "@/components/Button";
import Invoice from "@/components/Invoice";
import { Sheet } from "@/components/Sheet";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import { useGetNextInvoice } from "@/fetch/payment/getNextInvoice";
import { PaymentMethods } from "@/fetch/payment/helpers";

type Props = {
  subscription: Subscription;
  paymentMethod: PaymentMethod;
};

const NextInvoice: React.FC<Props> = ({ subscription, paymentMethod }) => {
  const t = useTranslate(messages);

  const { data, error, isLoading } = useGetNextInvoice({
    subscriptionId: subscription.id,
  });

  const infoText: Record<string, string> = {
    CREDIT_CARD: t("infoTextCreditCard"),
    EINVOICE: t("infoTextElectronicInvoice"),
    AUTOINVOICE: t("infoTextElectronicInvoice"),
  };

  const renderNextInvoice = (invoice: InvoiceType) => {
    const text = infoText[invoice.paymentMethod];
    const invoicePaymenMethod = invoice.paymentMethod.toLowerCase();

    const autoInvoiceAvailable = paymentMethod.availablePaymentMethods?.some(
      (x) => x.method === PaymentMethods.AVTALEGIRO,
    );

    // TODO: Make a list of white listed payment methods that can be changed to autoinvoice
    // for example: creditcard, invoice
    const showChangeToAutoInvoice =
      autoInvoiceAvailable &&
      !invoicePaymenMethod.includes("autoinvoice") &&
      invoicePaymenMethod !== "vipps" &&
      invoicePaymenMethod !== "unknown";

    return (
      <>
        <h2>{t("nextInvoice")}</h2>
        {text && <p>{text}</p>}
        <Invoice invoice={invoice} />

        {showChangeToAutoInvoice && (
          <StartAutoInvoiceForm
            subscriptionId={subscription.id}
            kidNumber={subscription.kidNumber}
            submitButton={() => (
              <TextButton type="submit">{t("changeToAutoInvoice")}</TextButton>
            )}
          />
        )}
      </>
    );
  };

  if (isLoading) {
    return (
      <Sheet>
        <List uniqueKey="NextInvoice.Loading" />
      </Sheet>
    );
  }

  if (error) {
    log.error(t("error"), error);
    return (
      <Sheet>
        <p>{t("error")}</p>
      </Sheet>
    );
  }

  if (data.invoice) {
    return <Sheet>{renderNextInvoice(data.invoice)}</Sheet>;
  }

  return (
    <Sheet>
      <h2>{t("noInvoice")}</h2>
      <p>{t("allPaid")}</p>
    </Sheet>
  );
};

export default NextInvoice;

const messages = {
  nextInvoice: {
    nb: "Neste betaling",
    nn: "Neste betaling",
  },
  noInvoice: {
    nb: "Alt er betalt!",
    nn: "Alt er betalt!",
  },
  allPaid: {
    nb: "Du har ingen varsler om betaling av ditt abonnement.",
    nn: "Du har ingen varsel om betaling av ditt abonnement.",
  },
  infoTextElectronicInvoice: {
    nb: "Vi har sendt deg en faktura i nettbanken.",
    nn: "Vi har sendt deg ein faktura i nettbanken.",
  },
  infoTextCreditCard: {
    nb: "Betalingen trekkes fra betalingskortet ditt på forfallsdatoen.",
    nn: "Betalinga vert trekt frå betalingskortet ditt på forfallsdatoen.",
  },
  changeToAutoInvoice: {
    nb: "Betal denne og fremtidige betalinger med AvtaleGiro.",
    nn: "Betal denne og framtidlege betalingar med AvtaleGiro.",
  },
  error: {
    nb: "Greide ikke laste neste faktura.",
    nn: "Greidde ikkje laste neste faktura.",
  },
};
