import React from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

import { useTranslate } from "@/i18n";
import colors from "@/theme/colors";
import routes from "@/routes/routes";
import { AddressChange } from "@/fetch/address/getAddressChanges";

type Props = {
  changes: {
    active: AddressChange[];
    future: AddressChange[];
  };
  subscriptionId: string;
};

const DeliveryChanges: React.FC<Props> = ({ changes, subscriptionId }) => {
  const t = useTranslate(messages);

  const texts: Record<string, Record<string, string>> = {
    title: { active: t("activeChange"), future: t("futureChange") },
    changeType: {
      STOP: t("deliveryStop"),
      DELIVER_TEMPORARY: t("temporaryChange"),
      DELIVER_PERMANENT: t("permanentChange"),
    },
  };

  return (
    <ChangesContainer>
      {Object.entries(changes).map(
        ([changeCategory, values]) =>
          values.length > 0 && (
            <div key={changeCategory}>
              <h3>{texts.title[changeCategory]}</h3>
              {values.map((change) => (
                <Change key={`${change.id}-${change.startDate}`}>
                  <strong>{texts.changeType[change.type]}</strong>
                  <ChangeDate>
                    {change.startDate.format("DD.MM.YY")} -{" "}
                    {change.endDate && change.endDate.format("DD.MM.YY")}
                  </ChangeDate>
                </Change>
              ))}
            </div>
          ),
      )}
      <Link to={routes.changes.path(subscriptionId)}>
        {t("subscriptionChangesLink")}
      </Link>
    </ChangesContainer>
  );
};

export default DeliveryChanges;

const ChangesContainer = styled.div`
  padding: 25px;
  margin-top: 20px;
  background: ${colors.paleYellow};

  h3 {
    margin-top: 0;
  }

  a:last-child {
    display: inline-block;
    margin-top: 10px;
  }
`;

const Change = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ChangeDate = styled.div`
  margin-bottom: 10px;
  color: ${colors.grayText};
`;

const messages = {
  activeChange: {
    nb: "Skjer nå",
    nn: "Hender no",
  },
  futureChange: {
    nb: "Planlagte endringer",
    nn: "Planlagde endringar",
  },
  deliveryStop: {
    nb: "Midlertidig stans av levering",
    nn: "Mellombels stans av levering",
  },
  permanentChange: {
    nb: "Ny fast leveringsadresse",
    nn: "Ny fast leveringsadresse",
  },
  temporaryChange: {
    nb: "Midlertidig endring av leveringsadresse",
    nn: "Mellombels endring av leveringsadresse",
  },
  subscriptionChangesLink: {
    nb: "Administrer leveringsendringer",
    nn: "Administrer leveringsendringar",
  },
};
