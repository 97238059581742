import { useEffect } from "react";

type Script = {
  id: string;
  src: string;
  async?: boolean;
  type?: string;
  extraAttributes?: { [key: string]: string };
};

export const useScript = (props: Script) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = props.id;
    script.src = props.src;
    script.async = props.async || true;
    script.type = props.type || "text/javascript";

    if (props.extraAttributes) {
      Object.entries(props.extraAttributes).forEach(([key, value]) => {
        script.setAttribute(key, value);
      });
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [props.async, props.extraAttributes, props.id, props.src, props.type]);
};
