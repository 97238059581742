import useSwr from "swr";
import dayjs, { Dayjs } from "dayjs";
import { AddressChange as AddressChangeDelaware } from "@amedia/node-clients-abo";

import { fetcher } from "../fetcher";
import { FetcherError } from "../errors";

export type AddressChange = Omit<
  AddressChangeDelaware,
  "startDate" | "endDate"
> & {
  startDate: Dayjs;
  endDate?: Dayjs;
};

type AddressChangesResponse = {
  addressChanges: AddressChangeDelaware[];
};

const mapAddressChange = (change: AddressChangeDelaware): AddressChange => ({
  ...change,
  startDate: dayjs(change.startDate),
  endDate: change.endDate ? dayjs(change.endDate) : undefined,
});

export const useGetAddressChanges = ({
  subscriptionId,
}: {
  subscriptionId: string;
}) => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/address/changes?`;

  const { data, error, isLoading, mutate } = useSwr<
    AddressChangesResponse,
    Error | FetcherError
  >([url, { method: "GET" }], fetcher);

  return {
    addressChanges:
      data?.addressChanges.map((change) => mapAddressChange(change)) || [],
    ...(error && { error }),
    isLoading,
    forceRefetch: () => mutate(),
  };
};
