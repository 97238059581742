import useSwr from "swr";

import { FetcherError, errorToString } from "../errors";
import { fetcher, handleResNotOk } from "../fetcher";

export type ComplaintCode = {
  code: string;
  name: string;
};

type ComplaintCodesResponse = {
  complaintCodes: ComplaintCode[];
  error?: FetcherError | Error;
};

type ComplaintCodesResult = {
  isLoading: boolean;
  complaintCodes: ComplaintCode[];
  error?: FetcherError | Error;
};

export const useComplaintCodesFetcher = ({
  subscriptionId,
}: {
  subscriptionId: string;
}): ComplaintCodesResult => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/complaints/codes`;

  const { data, error, isLoading } = useSwr<ComplaintCodesResponse, Error>(
    [url, { method: "GET" }],
    fetcher,
  );

  if (data?.error) {
    return {
      complaintCodes: [],
      error: data.error,
      isLoading,
    };
  }

  return {
    complaintCodes: data?.complaintCodes || [],
    ...(error && { error }),
    isLoading,
  };
};

type RegisterComplaint = {
  subscriptionId: string;
  formData: {
    code: string;
    date: string;
  };
};

export enum RegisterComplaintStatus {
  Success = "success",
  ValidationError = "validation_error",
  Error = "error",
}

type RegisterComplaintSuccess = {
  status: RegisterComplaintStatus.Success;
};

type RegisterComplaintValidationError = {
  status: RegisterComplaintStatus.ValidationError;
};

type RegisterComplaintError = {
  status: RegisterComplaintStatus.Error;
  error: string;
};

type RegisterComplaintResponse =
  | RegisterComplaintSuccess
  | RegisterComplaintValidationError
  | RegisterComplaintError;

export const registerComplaint = async ({
  subscriptionId,
  formData,
}: RegisterComplaint): Promise<RegisterComplaintResponse> => {
  const url = `/api/vorwerk/subscription/${subscriptionId}/complaints/register`;

  const fetchOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ formData }),
  };

  try {
    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      return handleResNotOk(response);
    }
    const data = (await response.json()) as RegisterComplaintResponse;
    return data;
  } catch (error) {
    return {
      status: RegisterComplaintStatus.Error,
      error: `RegisterComplaint failed. Error: ${errorToString(error)}`,
    };
  }
};
