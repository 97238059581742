import React from "react";

import { Translation, useTranslate } from "@/i18n";

type Props = { text: Translation };

/**
 * This can be used for printing a generated text that contains a translation.
 */
const TranslatedText: React.FC<Props> = ({ text }) => {
  const t = useTranslate({ text });
  return <>{t("text")}</>;
};

export default TranslatedText;
