import { FeatureButton } from "@/components/CurrentSubscriptionCard/currentSubscriptionCard";

export const productFeatureColor = {
  plussalt: "#e0d3ea",
  podcast: "#fce6ff",
  direktesport: "#d1e6ff",
  altno: "#ffe5da",
  default: "#e5e0ce",
} as const;

export const productFeatureButtons: FeatureButton[] = [
  {
    id: "plussalt",
    label: "100+ aviser",
    bgColor: productFeatureColor.plussalt,
  },
  {
    id: "digital",
    label: "Avisappen",
    bgColor: productFeatureColor.default,
  },
  {
    id: "shareable",
    label: "Hustandsdeling",
    bgColor: productFeatureColor.default,
  },
  {
    id: "podcast",
    label: "Podkast i Untold",
    bgColor: productFeatureColor.podcast,
  },
  {
    id: "direktesport",
    label: "Direktesport",
    bgColor: productFeatureColor.direktesport,
  },
  {
    id: "altno",
    label: "ALT",
    bgColor: productFeatureColor.altno,
  },
  {
    id: "epaper",
    label: "eAviskiosken",
    bgColor: productFeatureColor.default,
  },
  {
    id: "paper_weekdays",
    label: "eAvis",
    bgColor: productFeatureColor.default,
  },
];
