import useSwr from "swr";
import type { GetOfferings } from "@amedia/node-clients-abo";

import { fetcher } from "./fetcher";

import { FetcherError } from "@/fetch/errors";
import { Locale } from "@/common/types";
import { Offering } from "@/fetch/mappers/offeringMapper";

interface OfferingFetcherResult {
  offerings: Offering[];
  error?: FetcherError | Error;
}

export const useOfferingFetcher = (
  options: Omit<GetOfferings, "servicesBaseUrl" | "publicationDomain">,
  locale: Locale,
): OfferingFetcherResult => {
  const url = `/api/vorwerk/offerings`;

  const headers = {
    "Content-Type": "application/json",
    ...(options.persona && {
      "X-Test_Person": options.persona,
    }),
  };
  const fetchOptions = {
    method: "POST",
    headers,
    body: JSON.stringify({
      locale,
      ...(options.publicationAccess && {
        publicationAccess: options.publicationAccess,
      }),
      ...(options.filterBy && { filterBy: options.filterBy }),
      ...(options.orderBy && { orderBy: options.orderBy }),
      ...(options.channel && { channel: options.channel }),
      ...(options.persona && { persona: options.persona }),
    }),
  };

  const { data, error } = useSwr<
    { offerings: Array<Offering> },
    Error | FetcherError
  >([url, fetchOptions], fetcher);

  return {
    offerings: data?.offerings ? data.offerings : [],
    ...(error && { error }),
  };
};
