import { css } from "styled-components";
import { css as cssBrick } from "@amedia/brick-tokens";

export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const brickGlobalStyle = cssBrick({
  fontstyle: "baseBodyS",
  color: "$baseFg",
})();
