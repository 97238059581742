export enum PaymentMethods {
  // Payment methods used in logic. Add more if needed. There are no restrictions on current payment
  // method sent by backend, all should be data driven. However, changing payment method has
  // restrictions and those are enforced by the respective component(s).
  AVTALEGIRO = "AVTALEGIRO",
  CREDIT_CARD = "CREDIT_CARD",
  EFAKTURA = "EFAKTURA",
  VIPPS = "VIPPS",
  BANKGIRO = "BANKGIRO",
}

export enum UpdatePaymentStatus {
  SUCCESS = "success",
  ERROR = "error",
}

export type PaymentStatusType = "autoInvoice" | "replaceCard";

export enum CallbackType {
  CREDIT_CARD = "kredittkort",
  AUTO_INVOICE = "avtalegiro",
}
