import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaComments } from "react-icons/fa";
import type { ChatConfig } from "site-config-site";
import { styled, css } from "styled-components";

import { PrimaryButton } from "@/components/Button";
import { TextInputField } from "@/components/InputField";
import { Sheet } from "@/components/Sheet";
import AppContext from "@/contexts/appContext";
import { useScript } from "@/hooks/useScript";
import { TranslationFunction, useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import { getCircularReplacer } from "@/logging/utils";
import { SessionStatus } from "@/services/amedia-user/login";
import colors from "@/theme/colors";
import { FormErrors, useForm } from "@/utils/form";
import { stripWww } from "@/utils/string";

type FormProps = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const validator = (
  { phoneNumber }: FormProps,
  t: TranslationFunction<typeof formMessages>,
): FormErrors<FormProps> => {
  const errors: FormErrors<FormProps> = {};

  if (
    !phoneNumber ||
    !/^\d+$/.exec(phoneNumber) ||
    phoneNumber.length !== 8 ||
    (!phoneNumber.startsWith("4") &&
      !phoneNumber.startsWith("9") &&
      !phoneNumber.startsWith("0000"))
  ) {
    errors.phoneNumber = t("phoneNumber");
  }
  return errors;
};

const formMessages = {
  phoneNumber: {
    nb: "Ugyldig mobilnummer",
  },
};

const scrollToRef = (ref: React.MutableRefObject<HTMLDivElement | null>) =>
  ref.current && window.scrollTo(0, ref.current.offsetTop);

enum ChatState {
  form,
  chat,
  ended,
}

const chatDomId = "amedia-chatContainer";
const mobileMediaQuery = "max-width: 767px";

type Props = {
  chatConfig: ChatConfig;
};

const ChatView: React.FC<Props> = ({ chatConfig }) => {
  const t = useTranslate(messages);
  const context = useContext(AppContext);
  const { domain } = context.site;
  const publicationName = stripWww(domain);

  const [isOpen, openChat] = useState(false);
  const [chatState, setChatState] = useState(ChatState.form);

  const user =
    context.session.status === SessionStatus.SESSION_FOUND &&
    context.session.user;

  type Init = {
    firstName?: string;
    lastName?: string;
  };

  const initChat = useCallback(
    ({ firstName, lastName }: Init) => {
      const config = {
        orgId: chatConfig.orgId,
        orgName: chatConfig.orgName,
        queueName: chatConfig.queueName,
        welcomeMessage: t("welcomeMessage", { publicationName }),
        webchatAppUrl: "https://apps.mypurecloud.ie/webchat",
        webchatServiceUrl: "https://realtime.mypurecloud.ie:443",
        logLevel: "ERROR",
        locale: "no",
        companyLogo: `//g.acdn.no/local/v3/publications/${domain}/gfx/small-positive.svg`,
      };

      // TODO: ugly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const purecloudChat = (window as any).ININ;
      const chatDom = document.getElementById(chatDomId);
      if (chatDom) {
        // TODO: even uglier, clear any previous chats
        chatDom.innerHTML = "";
      }

      setChatState(ChatState.chat);

      purecloudChat.webchat.create(
        {
          ...config,
          data: { firstName, lastName },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: unknown, webchat: any) => {
          if (error) {
            log.error(
              "Failed to initialize PureCloud chat",
              error instanceof Error
                ? error
                : Error(JSON.stringify(error, getCircularReplacer())),
            );
            return;
          }

          webchat.renderFrame({
            containerEl: chatDomId,
          });

          webchat.chatEnded = () => {
            setChatState(ChatState.ended);
          };
        },
      );
    },
    [
      chatConfig.orgId,
      chatConfig.orgName,
      chatConfig.queueName,
      domain,
      publicationName,
      t,
    ],
  );

  const initial: FormProps = useMemo(
    () => ({
      firstName: user ? user.name.first : "",
      lastName: user ? user.name.last : "",
      phoneNumber: "",
    }),
    [user],
  );

  const { submitForm, isSubmitting, propsForField } = useForm(
    initial,
    formMessages,
    validator,
    initChat,
  );
  const chatRef = useRef<HTMLDivElement | null>(null);
  const scrollToChat = () => scrollToRef(chatRef);

  useScript({
    id: "purecloud-webchat-js",
    src: "https://apps.mypurecloud.ie/webchat/jsapi-v1.js",
    extraAttributes: {
      region: "eu-west-1",
      "org-guid": chatConfig.pureCloud.orgGuid,
      "deployment-key": chatConfig.pureCloud.deploymentKey,
    },
  });

  if (!user) {
    return null;
  }

  return (
    <>
      <MobileOnlySheet ref={chatRef}>
        <ChatWrapper isOpen={isOpen}>
          <ChatButtonWrapper>
            <ChatButton
              onClick={() => {
                const mobile = window.matchMedia(`(${mobileMediaQuery})`);
                if (mobile.matches) {
                  scrollToChat();
                } else {
                  openChat((prev) => !prev);
                }
              }}
            >
              {t("title")} <ChatIcon />
            </ChatButton>
          </ChatButtonWrapper>
          <ChatBox isOpen={isOpen}>
            {chatState === ChatState.ended && (
              <ChatEnded>
                {t("ended")}
                <NewChatButton
                  onClick={() =>
                    initChat({
                      firstName: user.name.first,
                      lastName: user.name.last,
                    })
                  }
                >
                  {t("new")}
                </NewChatButton>
                <a href="/vis/info/qa">{t("faq")}</a>
              </ChatEnded>
            )}
            {chatState === ChatState.form && (
              <ChatForm onSubmit={submitForm}>
                <TextInputField
                  description={t("firstName")}
                  {...propsForField("firstName")}
                />
                <TextInputField
                  description={t("lastName")}
                  {...propsForField("lastName")}
                />
                <TextInputField
                  description={t("phoneNumber")}
                  maxLength={8}
                  {...propsForField("phoneNumber")}
                />

                <PrimaryButton disabled={isSubmitting} type="submit">
                  {t("start")}
                </PrimaryButton>
              </ChatForm>
            )}
            <AmediaChatContainer
              active={[ChatState.chat, ChatState.ended].includes(chatState)}
              id={chatDomId}
            ></AmediaChatContainer>
          </ChatBox>
        </ChatWrapper>
      </MobileOnlySheet>
    </>
  );
};

const MobileOnlySheet = styled(Sheet)`
  @media (min-width: 767px) {
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
`;

const ChatWrapper = styled.div<{ isOpen: boolean }>`
  bottom: 0;
  right: 50px;
  position: fixed;
  z-index: 100;

  @media (${mobileMediaQuery}) {
    position: static;
    height: auto;
    overflow: visible;
    z-index: unset;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
      right: 50px;
      min-height: 400px;

      @media (${mobileMediaQuery}) {
        min-height: inherit;
      }
    `}
`;

const ChatIcon = styled(FaComments)`
  color: ${colors.white};
  width: 25px;
  height: auto;
`;

const ChatButtonWrapper = styled.div`
  width: 500px;
  text-align: right;
  /* height: 80px; */
  overflow: hidden;

  @media (${mobileMediaQuery}) {
    position: fixed;
    width: 100%;
    bottom: 0;
    right: auto;
    left: 0;
    text-align: center;
  }
`;

const ChatButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 20px 40px;
  font-size: 20px;
  display: inline-block;
  font-weight: bold;
  color: ${colors.white};
  text-decoration: none;
  border: none;

  @media (${mobileMediaQuery}) {
    width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }

  &:active,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
`;

const ChatBox = styled.div<{ isOpen: boolean }>`
  display: none;
  padding: 20px;
  width: 500px;
  background: ${colors.pageBackground};
  border: 1px solid #ccc;

  @media (${mobileMediaQuery}) {
    display: block;
    width: 100%;
    background: inherit;
    border: none;
    padding: 0;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`;

const ChatForm = styled.form``;

const AmediaChatContainer = styled.div<{ active: boolean }>`
  display: none;
  padding: 0 20px;

  @media (${mobileMediaQuery}) {
    position: static;
    width: 100%;
  }

  iframe {
    border: none;
  }

  ${({ active }) =>
    active &&
    css`
      display: block;
      background: #f6f5f1;
      height: 400px;
      width: 498px;

      @media (${mobileMediaQuery}) {
        width: 100%;
      }
    `}
`;

const NewChatButton = styled.button`
  font-weight: bold;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
`;

const ChatEnded = styled.div`
  width: 100%;
  padding: 10px;
  text-align: left;

  font-size: 12px;
  line-height: 14px;

  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
`;

export default ChatView;

const messages = {
  title: {
    nb: "Trykk her for å chatte med oss",
    nn: "Trykk her for å chatte med oss",
  },
  ended: {
    nb: "Chat er avsluttet, fordi en av partene har lukket samtalen eller på grunn av inaktivitet. Har du flere spørsmål?",
    nn: "Chat er avslutta, fordi ein av partane har lukka samtalen eller på grunn av inaktivitet. Har du fleire spørsmål?",
  },
  new: {
    nb: "Start ny chat.",
    nn: "Start ny chat.",
  },
  start: {
    nb: "Start chat",
    nn: "Start chat",
  },
  faq: {
    nb: "Klikk her for ofte stilte spørsmål og svar",
    nn: "Klikk her for ofte stilte spørsmål og svar",
  },
  firstName: {
    nb: "Fornavn",
    nn: "Fornamn",
  },
  lastName: {
    nb: "Etternavn",
    nn: "Etternamn",
  },
  phoneNumber: {
    nb: "Mobilnummer",
    nn: "Mobilnummer",
  },
  welcomeMessage: {
    nb: "Velkommen til {{publicationName}}. Du blir straks tildelt en salgskonsulent, hva kan vi hjelpe deg med?",
    nn: "Velkommen til {{publicationName}}. Du blir straks tildelt en salskonsulent, kva kan vi hjelpe deg med?",
  },
};
