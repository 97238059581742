export const untoldCovers = [
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/Hjernesterk_MM_NY_UNTOLD.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/WolfgangWeeUncut040822110734.jpeg",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/historiepodden_Banner_v01.jpg",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/boddel_cover_standard.jpg",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/debrief_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/diktatorpodden cover.jpg",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/en_liten_pause_untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/fag_og_feminin_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/familieliv_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/fantasyrådet_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/fotballfortellinger_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/henrettelsespodden_MM_NY.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/krimprat_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/laligaloca_Ny_UNTOLD.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/rinnan_Cover.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/sykt_Ærlig_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/synderne_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/tcp_DOK_MM_NY_UNTOLD.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/tcp_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/truecrimepodden_Dokumentar_1_Standard_Cover.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/uwp_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/vinnere_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/virkelig_Grusomt_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/ynta_MM_NY_Untold.png",
  "https://assets.acdn.no/local/v3/common/gfx/subscription-onboarding/untold/ynta_MM_NY_Untold.png",
];
