import { UserDataRequest } from "@amedia/user";

export enum SessionStatus {
  SESSION_FOUND = "session found",
  SESSION_NOT_FOUND = "session not found",
  SESSION_ERROR = "session error",
  UNINITIALIZED = "uninitialized",
}

export type User = {
  name: {
    full: string;
    first: string;
    last: string;
  };
  access: string[];
  uuid: string;
  extraData: {
    inCampaign: boolean;
    showAutoInvoicePromo: boolean;
  };
};

type SessionFound = { status: SessionStatus.SESSION_FOUND; user: User };
type SessionNotFound = { status: SessionStatus.SESSION_NOT_FOUND };
type SessionError = { status: SessionStatus.SESSION_ERROR };
type Uninitialized = { status: SessionStatus.UNINITIALIZED };

export type SessionData =
  | SessionFound
  | SessionNotFound
  | SessionError
  | Uninitialized;

interface SessionFetcherResult {
  session: SessionData;
}

export const getUserSession = async (): Promise<SessionFetcherResult> =>
  new UserDataRequest()
    .withAttributes(["name", "access", "uuid", "extraData"])
    .fetch()
    .then(({ state, attributes }) => {
      if (state.isLoggedIn && attributes.name && attributes.uuid) {
        const [lastName, ...firstName] = attributes.name.split(" ").reverse();
        return {
          session: {
            status: SessionStatus.SESSION_FOUND,
            user: {
              name: {
                full: attributes.name,
                first: firstName.reverse().join(" "),
                last: lastName,
              },
              access: attributes.access || [],
              uuid: attributes.uuid,
              extraData: {
                inCampaign:
                  attributes.extraData["sub_category"] === "kampanjeperiode",
                showAutoInvoicePromo:
                  attributes.extraData["card_expiry_direct_debit"] === true,
              },
            },
          },
        };
      }
      return {
        session: { status: SessionStatus.SESSION_NOT_FOUND },
      };
    });
