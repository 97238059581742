import { meetsContrastGuidelines } from "polished";
import React, { useContext } from "react";
import { ThemeProvider } from "styled-components";

import AppContext from "@/contexts/appContext";

export interface ThemeInterface {
  primaryColor: string;
  secondaryColor: string;
}

type Props = {
  children: React.ReactNode;
};

const StateThemeProvider: React.FC<Props> = ({ children }) => {
  const { primary, secondary } = useContext(AppContext).gaia.publicationColor;

  const theme: ThemeInterface = {
    primaryColor: meetsContrastGuidelines(primary, "#fff").AA
      ? primary
      : "#000",
    secondaryColor: secondary,
  };

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StateThemeProvider;
