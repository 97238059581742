import useSwr from "swr";
import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";

import { fetchWithExtendedError } from "@/fetch/fetch";
import { DecodingError, FetcherError } from "@/fetch/errors";

const ChatSchema = t.type({
  open: t.boolean,
});

type Chat = t.TypeOf<typeof ChatSchema>;

interface ChatResult {
  open: boolean;
  error?: FetcherError | Error;
}

export const useChatStatus = (): ChatResult => {
  const { data, error } = useSwr<Chat, Error>(
    `${window.location.origin}/api/vorwerk/chat`,
    fetchWithExtendedError(chatDecoder),
  );

  return {
    open: !!data?.open,
    ...(error && { error }),
  };
};

const chatDecoder = (response: Chat) => {
  const decodedValue = ChatSchema.decode(response);
  if (decodedValue._tag === "Left") {
    const error = new DecodingError("Could nok decode chat request");
    error.decoderReport = PathReporter.report(decodedValue);
    throw error;
  }
  return decodedValue.right;
};
