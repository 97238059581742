import React from "react";
import { styled } from "styled-components";

import CustomerServiceInfo from "../CustomerServiceInfo";

import { Sheet } from "@/components/Sheet";
import Chat from "@/components/Chat";
import { useTranslate } from "@/i18n";

const Help: React.FC = () => {
  const t = useTranslate(messages);

  return (
    <>
      <Sheet>
        <Header>{t("needHelpMessage")}</Header>
        <CustomerServiceInfo />
        <div>
          <p>
            <a style={{ color: "inherit" }} href="/vis/info/qa">
              {t("faq")}
            </a>
          </p>
        </div>
        <div>
          <p>
            <a
              style={{ color: "inherit" }}
              href="/vis/info/kontakt-kundesenteret"
            >
              {t("request")}
            </a>
          </p>
        </div>
      </Sheet>
      <Chat />
    </>
  );
};

const Header = styled.h2`
  margin: 0 0 25px 0;
`;

export default Help;

const messages = {
  needHelpMessage: {
    nb: "Trenger du hjelp?",
    nn: "Treng du hjelp?",
  },
  faq: {
    nb: "Ofte stilte spørsmål",
    nn: "Ofte stilte spørsmål",
  },
  request: {
    nb: "Send oss en henvendelse",
    nn: "Send oss ein førespurnad",
  },
};
