import React from "react";
import { BulletList } from "react-content-loader";
import { styled } from "styled-components";

import PaymentInfo from "./paymentInfo";

import { useTranslate } from "@/i18n";
import { useGetPaymentMethodById } from "@/fetch/payment/getPaymentMethodById";

type Props = {
  paymentId: string;
};

const PaymentInfoWrapper: React.FC<Props> = ({ paymentId }) => {
  const t = useTranslate(messages);
  const res = useGetPaymentMethodById({
    id: paymentId,
  });

  return (
    <>
      <PaymentSection>
        <PaymentHeader>{t("currentPaymentMethod")}:</PaymentHeader>
        {!res && (
          <BulletList
            uniqueKey="PaymentMethod.MyPage.Loading"
            data-testid="loading-payment"
          />
        )}
        {res.paymentMethod && <PaymentInfo paymentMethod={res.paymentMethod} />}
        {res.error && <p>{t("paymentMethodError")}</p>}
      </PaymentSection>
    </>
  );
};

const PaymentSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  p {
    margin-bottom: 0;
  }
`;

const PaymentHeader = styled.div`
  font-size: small;
  font-weight: bold;
  margin-bottom: 7px;
`;

export default PaymentInfoWrapper;

const messages = {
  paymentMethodError: {
    nb: "Greide ikke finne betalingsinformasjonen din",
    nn: "Greide ikkje finne betalingsinformasjonen din",
  },
  currentPaymentMethod: {
    nb: "Betalingsmetode",
    nn: "Betalingsmetode",
  },
};
