import React from "react";

import { PrimaryButton } from "@/components/Button";
import PageHeader from "@/components/PageHeader";
import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";

const ResubscribeReceipt: React.FC = () => {
  const t = useTranslate(messages);

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Sheet>
        <p>{t("receipt")}</p>
        <a href="/">
          <PrimaryButton as="span">{t("toFrontpage")}</PrimaryButton>
        </a>
      </Sheet>
    </>
  );
};

export default ResubscribeReceipt;

const messages = {
  receipt: {
    nb: "Abonnementet ditt er aktivt igjen. Takk for at du vil fortsette å lese avisen.",
    nn: "Abonnementet ditt er aktivt igjen. Takk for at du vil fortsette å lese avisa.",
  },
  toFrontpage: {
    nb: "Til forsiden",
    nn: "Til framsida",
  },
  title: {
    nb: "Takk!",
    nn: "Takk!",
  },
};
