import { useContext } from "react";

import { SubscriptionInfo } from "./subscriptionInfo";

import AppContext from "@/contexts/appContext";

export const WelcomeInfoPage = () => {
  const context = useContext(AppContext);

  return context.subscriptions.data.map((sub) => (
    <SubscriptionInfo key={sub.id} subscription={sub} />
  ));
};
export default WelcomeInfoPage;
