import React, { PropsWithChildren, useState } from "react";
import AnimateHeight from "react-animate-height";
import { styled } from "styled-components";

import colors from "@/theme/colors";

type Props = PropsWithChildren & {
  openText: string;
  closeText: string;
  onToggleClose?: () => void;
  onToggleOpen?: () => void;
};

const PlusToggle: React.FC<Props> = ({
  children,
  openText,
  closeText,
  onToggleClose,
  onToggleOpen,
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded((e) => !e);
  return (
    <>
      <ToggleInput
        type="button"
        onClick={toggleExpanded}
        aria-expanded={expanded}
      >
        <ToggleIcon expanded={expanded} />
        {expanded ? closeText : openText}
      </ToggleInput>
      <AnimateHeight
        duration={500}
        height={expanded ? "auto" : 0}
        onHeightAnimationEnd={() =>
          expanded
            ? onToggleOpen && onToggleOpen()
            : onToggleClose && onToggleClose()
        }
      >
        {children}
      </AnimateHeight>
    </>
  );
};

const ToggleInput = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  margin: 10px 0;
  &:hover {
    cursor: pointer;
  }
`;

const ToggleIcon = styled.span<{ expanded: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid ${colors.blue};
  border-radius: 50%;
  position: relative;
  display: inline-block;
  margin-right: 4px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 12px;
    width: 2px;
    background: ${colors.blue};
    left: calc(50% - 1px);
    margin-top: 2px;
    transform: ${({ expanded }) => expanded && "rotate(90deg)"};
    transition: 0.1s;
  }

  &:after {
    transform: rotate(90deg);
  }
`;

export default PlusToggle;
