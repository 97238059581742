import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";
import dayjs from "dayjs";

import AppContext, { AppContextInterface } from "@/contexts/appContext";
import { FetcherError } from "@/fetch/errors";
import { i18nitClient, isI18nInitialized } from "@/i18n";
import { log } from "@/logging/logger";
import {
  SessionData,
  SessionStatus,
  getUserSession,
} from "@/services/amedia-user/login";
import { setMockUser } from "@/services/internal/setUser";
import StateThemeProvider from "@/theme/stateThemeProvider";
import { isDevelopment, isTest } from "@/utils/app";
import { mockUser } from "src/mocks/user";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { CatalanaProductFeatures } from "shared/types";

const SiteContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // Hydrated by backend in index.html
  const siteConfig = globalThis.indexHtmlSiteConfig;
  const gaiaData = globalThis.indexHtmlGaiaData;
  const relevantTitles = globalThis.indexHtmlRelevantTitles;
  const appContext = useContext(AppContext);
  const defaultValue = appContext;

  const [session, setSession] = useState<SessionData>({
    status: SessionStatus.UNINITIALIZED,
  });

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [fetchSubsError, setFetchSubsError] = useState<FetcherError | Error>();
  const [catalanaProductFeatures, setCatalanaProductFeatures] =
    useState<CatalanaProductFeatures>({});

  const handlePlaywrightUser = async () => {
    setSession({
      status: SessionStatus.SESSION_FOUND,
      user: await setMockUser(),
    });
  };

  const setDevMocks = () => {
    setSession({
      status: SessionStatus.SESSION_FOUND,
      user: mockUser.amediaStaff,
    });
  };

  const loadSession = async () => {
    await getUserSession()
      .then(({ session }) => {
        setSession(session);
      })
      .catch((err: Error) => {
        console.error("Session Error", session);
        log.error("Got error when loading session.", err);
        setSession({ status: SessionStatus.SESSION_ERROR });
      });
  };

  if (session.status === SessionStatus.UNINITIALIZED) {
    if (isTest) {
      handlePlaywrightUser();
    } else if (isDevelopment) {
      setDevMocks();
    } else {
      loadSession();
    }
  }

  const updateSubscriptions = (subscriptions: Subscription[]) => {
    setSubscriptions(subscriptions);
  };

  const setProductFeatures = (
    catalanaProductFeatures: CatalanaProductFeatures,
  ) => {
    setCatalanaProductFeatures(catalanaProductFeatures);
  };

  const value = useCallback(
    (): AppContextInterface => ({
      site: {
        domain: siteConfig?.domain || defaultValue.site.domain,
        name: siteConfig?.name || defaultValue.site.name,
        locale: siteConfig?.locale || defaultValue.site.locale,
        participatesInPlussalt: siteConfig.participatesInPlussalt || false,
        onboardingMode: siteConfig.onboardingMode || false,
        subscriptionSystem: siteConfig.subscriptionSystem || false,
        chatConfig: siteConfig.chatConfig,
        apps: siteConfig.apps || defaultValue.site.apps,
      },
      gaia: gaiaData || defaultValue.gaia,
      session,
      subscriptions: {
        set: updateSubscriptions,
        data: subscriptions,
        setError: setFetchSubsError,
        error: fetchSubsError,
      },
      catalana: {
        setProductFeatures: setProductFeatures,
        productFeatures: catalanaProductFeatures,
      },
      relevantTitles: relevantTitles || defaultValue.relevantTitles,
    }),
    [
      siteConfig?.domain,
      siteConfig?.name,
      siteConfig?.locale,
      siteConfig.participatesInPlussalt,
      siteConfig.onboardingMode,
      siteConfig.subscriptionSystem,
      siteConfig.chatConfig,
      siteConfig.apps,
      defaultValue.site.domain,
      defaultValue.site.name,
      defaultValue.site.locale,
      defaultValue.gaia,
      defaultValue.site.apps,
      defaultValue.relevantTitles,
      gaiaData,
      session,
      subscriptions,
      fetchSubsError,
      catalanaProductFeatures,
      relevantTitles,
    ],
  );

  if (!isI18nInitialized()) {
    i18nitClient(value().site.locale);
    dayjs.locale("nb"); // doesn't support "nynorsk"
  }

  return (
    <AppContext.Provider value={value()}>
      <StateThemeProvider>{children}</StateThemeProvider>
    </AppContext.Provider>
  );
};

export default SiteContextProvider;
