import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ReceiptData } from "../receiptTypes";

import AppContext from "@/contexts/appContext";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";

export const useNavigateToReceipt = () => {
  const subscription = useContext(AppContext).subscriptions.data[0];
  const navigate = useNavigate();

  const toReceipt = useCallback(
    (receiptData: ReceiptData) => {
      if (!subscription.id) {
        log.error("Tried to navigate to receipt, but no subscription found");
        navigate(routes.error.path());
      } else {
        history.replaceState(null, "", routes.myPage.path());
        navigate(routes.receipt.path(subscription.id), {
          state: receiptData,
        });
      }
    },
    [navigate, subscription.id],
  );

  return toReceipt;
};
