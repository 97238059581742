export default {
  pageBackground: "#f6f5f1",
  contrastBeigeDark: "#797569",
  contrastBeigeLight: "#d5d3ca",
  contrastGrayLight: "#eee",
  contrastGrayLighter: "#f7f7f7",
  contrastGrayDark: "#888",
  contrastGrayDarkHover: "#888583",
  inactiveGray: "#ddd",
  activeGray: "#a4a4a4",
  errorRedLight: "rgb(255, 170, 170)",
  errorRedDark: "rgba(255,0,0,0.7)",
  errorRedDarker: "#bd3d1a",
  okGreen: "#2c822c",
  grayText: "#757575",
  almostWhite: "#fafafa",
  almostBlack: "#333",
  white: "#fff",
  black: "#000",
  blue: "#006699",
  loadingPrimary: "#f3f3f3",
  loadingSecondary: "#ecebeb",
  masterCard: "#0a3a82",
  visa: "#0157a2",
  heartRed: "#a01f1f",
  lightBorderGray: "#bfbfbf",
  productListGray: "#686563",
  transparentBlack: "rgba(0, 0, 0, 0.3)",
  infoTitleBlack: "#4d4d4d",
  infoBackground: "#d9d8d4",
  amediaMagenta: "#ec008b",
  amediaLightGray: "#F6F5F1",
  amediaGray: "#E7E6E2",
  amediaGreen: "#4D772D",
  transparentWhite: "rgba(255, 255, 255, 0.85)",
  paleYellow: "#fbf4d8",
  yellow: "#FFD700",
};
