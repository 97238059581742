import React, { useContext } from "react";
import { styled } from "styled-components";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import { PrimaryButton } from "@/components/Button";
import AppContext from "@/contexts/appContext";

const activateSubscriptionUrl = (location: Location) => {
  const url = new URL(`https://${location.host}/tilgang/aktiver/abonnent`);
  url.searchParams.append("requestedUrl", location.href);

  return url.toString();
};

const ActivateSubscription: React.FC = () => {
  const t = useTranslate(messages);
  const { site } = useContext(AppContext);

  return (
    <Sheet $primary>
      <h2>{t("title", { publicationName: site.name.full })}</h2>
      <p>{t("description", { publicationName: site.name.full })}</p>
      <A href={activateSubscriptionUrl(window.location)}>
        <PrimaryButton>{t("cta")}</PrimaryButton>
      </A>
    </Sheet>
  );
};

const A = styled.a`
  margin-bottom: 20px;
`;

export default ActivateSubscription;

const messages = {
  title: {
    nb: "Har du allerede abonnement på {{publicationName}}?",
    nn: "Har du allereie abonnement på {{publicationName}}?",
  },
  description: {
    nb: "Aktiver abonnementet med din aID-bruker for å få full tilgang på {{publicationName}}. Det tar bare ett minutt.",
    nn: "Aktiver abonnementet med din aID-brukar for å få full tilgang på {{publicationName}}. Det tek berre eit minutt.",
  },
  cta: {
    nb: "Aktiver tilgangen her",
    nn: "Aktiver tilgangen her",
  },
};
