import React from "react";
import { PaymentMethod } from "@amedia/node-clients-abo";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import Address from "@/components/Address";
import PaymentInfo from "@/components/SubscriptionCard/paymentInfo";
import { SecondaryButton } from "@/components/Button";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { PaymentMethods } from "@/fetch/payment/helpers";
import { startChangeCreditCard } from "@/fetch/payment/creditCard";

type Props = {
  subscription: Subscription;
  paymentMethod: PaymentMethod;
};

const CurrentPaymentMethod: React.FC<Props> = ({
  subscription,
  paymentMethod,
}) => {
  const t = useTranslate(messages);

  const replaceWithCard = () =>
    startChangeCreditCard({
      subscriptionId: subscription.id,
    });

  const showReplaceCardBtn =
    paymentMethod.method === PaymentMethods.CREDIT_CARD &&
    paymentMethod.availablePaymentMethods.some(
      (availablePayment) =>
        availablePayment.method === PaymentMethods.CREDIT_CARD,
    );

  const currentDeliveryAddress = subscription.currentDeliveryAddresses[0];
  const showCurrentDeliveryAddress =
    ![
      PaymentMethods.CREDIT_CARD,
      PaymentMethods.AVTALEGIRO,
      PaymentMethods.EFAKTURA,
      PaymentMethods.VIPPS,
    ].some((method) => method.valueOf() === paymentMethod.method) &&
    currentDeliveryAddress &&
    currentDeliveryAddress.postalCode !== "9999";

  return (
    <Sheet>
      <h2>{t("title")}</h2>
      <PaymentInfo paymentMethod={paymentMethod} />

      {showCurrentDeliveryAddress && (
        <div>
          <strong>{t("invoiceAddress")}</strong>
          <Address {...subscription.currentDeliveryAddresses[0]} />
        </div>
      )}

      {showReplaceCardBtn && (
        <SecondaryButton onClick={replaceWithCard}>
          {t("changeCard")}
        </SecondaryButton>
      )}
    </Sheet>
  );
};

export default CurrentPaymentMethod;

const messages = {
  title: {
    nb: "Nåværende betalingsmetode",
    nn: "Noverande betalingsmetode",
  },
  invoiceAddress: {
    nb: "Fakturaadresse",
    nn: "Fakturaadresse",
  },
  changeCard: {
    nb: "Bytt kort",
    nn: "Byt kort",
  },
};
