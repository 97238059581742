import { ProductFeature } from "@/fetch/mappers/subscriptionMapper";

export const includesPlussalt = (productFeatures: ProductFeature[]) =>
  productFeatures.some(
    (pf) => pf.code === "plussalt" || pf.code === "pluss_alt",
  );

export const includesPaperDelivery = (productFeatures: ProductFeature[]) =>
  productFeatures.some(
    (pf) => pf.code === "paper_weekdays" || pf.code === "paper_weekend",
  );

export const includesShareable = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "shareable");

export const includesAltNo = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "altno");

export const includesEPaper = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "epaper");

export const includesDigital = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "digital");

export const includesDirektesport = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "direktesport");

export const includesPodcast = (productFeatures: ProductFeature[]) =>
  productFeatures.some((pf) => pf.code === "podcast");
