import React, { PropsWithChildren } from "react";

import PageHeader from "../PageHeader";
import { Sheet } from "../Sheet";

type Props = PropsWithChildren & {
  title: string;
};

const ErrorBlock: React.FC<Props> = ({ title, children }) => {
  return (
    <div data-testid="error-block">
      <PageHeader>{title}</PageHeader>
      {children && <Sheet>{children}</Sheet>}
    </div>
  );
};

export default ErrorBlock;
