import React from "react";
import { styled } from "styled-components";

import { pageTitle } from "@/utils/page";
import { useTranslate } from "@/i18n";
import Help from "@/components/Help";
import { Sheet } from "@/components/Sheet";

const PageNotFound: React.FC = () => {
  const t = useTranslate(messages);
  pageTitle.set(t("title"));

  return (
    <>
      <Title>{t("title")}</Title>
      <Sheet>{t("text")}</Sheet>
      <Help />
    </>
  );
};

const Title = styled.h1`
  text-align: center;
`;

const messages = {
  title: {
    nb: "Siden finnes ikke",
    nn: "Sidan finst ikkje",
  },
  text: {
    nb: "Det ser ut som denne siden ikke eksisterer. Kanskje adressen er stavet feil, eller så kan siden ha blitt slettet eller flyttet.",
    nn: "Det ser ut som denne sida ikkje eksisterer. Kanskje adressa er stava feil, eller så kan sidan ha vorte sletta eller flytta.",
  },
};

export default PageNotFound;
