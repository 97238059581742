const amediaStaff = {
  name: {
    full: "Oswald Cobblepot (development)",
    first: "Oswald Cobblepot",
    last: "(development)",
  },
  access: ["pluss", "amedia_staff"],
  uuid: "1",
  extraData: {
    inCampaign: false,
    showAutoInvoicePromo: false,
  },
};

const newspaperOnly = {
  name: {
    full: "Jervis Tetch (development)",
    first: "Jervis Tetch",
    last: "(development)",
  },
  access: ["pluss"],
  uuid: "2",
  extraData: {
    inCampaign: false,
    showAutoInvoicePromo: false,
  },
};

const inCampaign = {
  name: {
    full: "Selina Kyle (development)",
    first: "Selina Kyle",
    last: "(development)",
  },
  access: ["pluss"],
  uuid: "3",
  extraData: {
    inCampaign: true,
    showAutoInvoicePromo: false,
  },
};

const showAutoInvoicePromo = {
  name: {
    full: "Edward Nigma (development)",
    first: "Edward Nigma",
    last: "(development)",
  },
  access: ["pluss"],
  uuid: "4",
  extraData: {
    inCampaign: false,
    showAutoInvoicePromo: true,
  },
};

const noAccess = {
  name: {
    full: "Victor Fries (development)",
    first: "Victor Fries",
    last: "(development)",
  },
  access: [],
  uuid: "5",
  extraData: {
    inCampaign: false,
    showAutoInvoicePromo: false,
  },
};

export const mockUser = {
  amediaStaff,
  newspaperOnly,
  inCampaign,
  showAutoInvoicePromo,
  noAccess,
};
