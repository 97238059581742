import { FetcherError } from "@amedia/node-clients-abo"; // TODO fix import after other pr
import useSwr from "swr";

import { fetcher } from "./fetcher";

export type SimpleSubscription = {
  name: string;
  productFeatures: Array<{ code: string }>;
  publicationDomain: string;
};

type SimpleSubscriptions = {
  simpleSubscriptions: Array<SimpleSubscription>;
};

export const useSimpleSubscriptionFetcher = () => {
  const url = `/api/vorwerk/subscriptions/simple`;

  const fetchOptions = {
    method: "GET",
  };

  const { data, error } = useSwr<SimpleSubscriptions, Error | FetcherError>(
    [url, fetchOptions],
    fetcher,
  );

  if (data) {
    return { simpleSubscriptions: data.simpleSubscriptions };
  }

  return { error };
};
