import React, { useContext } from "react";

import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";

const CustomerServiceInfo: React.FC = () => {
  const t = useTranslate(messages);
  const { phone } = useContext(AppContext).gaia.customerService;

  return (
    <div>
      <p>{t("phoneHelp", { phone })}</p>
      <p>{t("openingHoursHelp")}</p>
    </div>
  );
};

export default CustomerServiceInfo;

const messages = {
  phoneHelp: {
    nb: "Telefon: {{phone}}",
    nn: "Telefon: {{phone}}",
  },
  openingHoursHelp: {
    nb: "Åpningstider: 08.00 - 15.30 (mandag til fredag)",
    nn: "Opningstider: 08.00 - 15.30 (måndag til fredag)",
  },
};
