import React from "react";
import { Navigate, useOutletContext, useParams } from "react-router-dom";

import { AddressChangeSlug } from "@/pages/ChangeAddressPage/changeAddressPage";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute";
import routes from "@/routes/routes";
import { absoluteUrl } from "@/utils/url";

const RedirectPage: React.FC = () => {
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const { target } = useParams();

  const externalRedirectsMap: { [key: string]: string } = {
    "endre-abonnement": absoluteUrl("/tilbud").href,
  };

  const internalRedirectsMap: { [key: string]: string } = {
    betalingsoversikt: routes.payment.path(subscription.id),
    "klage-pa-levering": routes.deliveryComplaint.path(subscription.id),
    "midlertidig-adresseendring": routes.changeAddress.path(
      subscription.id,
      AddressChangeSlug.Temporary,
    ),
    "permanent-adresseendring": routes.changeAddress.path(
      subscription.id,
      AddressChangeSlug.Permanent,
    ),
  };

  if (target && externalRedirectsMap[target]) {
    window.location.replace(externalRedirectsMap[target]);
    return null;
  }

  const internalRedirectPath =
    (target && internalRedirectsMap[target]) || routes.myPage.path();
  return <Navigate to={internalRedirectPath} replace={true} />;
};

export default RedirectPage;
