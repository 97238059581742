import React, { useContext } from "react";
import { useOutletContext } from "react-router-dom";

import { NavigateToErrorPage } from "../ErrorPage/errorPage";

import AddressChanges from "./components/addressChanges";

import BreadCrumbs from "@/components/Breadcrumbs";
import PageHeader from "@/components/PageHeader";
import { SubscriptionOutlet } from "@/components/SubscriptionRoute/subscriptionRoute";
import AppContext from "@/contexts/appContext";
import { useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { pageTitle } from "@/utils/page";
import Spinner from "@/components/Spinner";
import { deleteChange } from "@/fetch/subscription/deleteChange";
import { useGetAddressChanges } from "@/fetch/address/getAddressChanges";

const SubscriptionChangesPage: React.FC = () => {
  const context = useContext(AppContext);
  const { locale, name } = context.site;
  const { subscription } = useOutletContext<SubscriptionOutlet>();
  const title = routes.changes.title[locale];

  const t = useTranslate({
    noChanges: { nb: "Ingen adresseendringer", nn: "Ingen adresseendringar" },
  });

  pageTitle.set(pageTitle.create(name.short, title));

  const { addressChanges, error, isLoading, forceRefetch } =
    useGetAddressChanges({
      subscriptionId: subscription.id,
    });

  const doDeleteChange = (changeId: string) => {
    deleteChange({
      subscriptionId: subscription.id,
      changeId,
    }).then(() => {
      forceRefetch();
    });
  };

  if (error) {
    log.error("Failed to fetch address changes.", error);
    return <NavigateToErrorPage errorCode="address" />;
  }

  const Changes: React.FC = () =>
    addressChanges && addressChanges.length > 0 ? (
      <AddressChanges
        addressChanges={addressChanges}
        doDeleteChange={doDeleteChange}
      />
    ) : (
      <PageHeader>{t("noChanges")}</PageHeader>
    );

  return (
    <>
      <BreadCrumbs>{title}</BreadCrumbs>
      {isLoading ? <Spinner /> : <Changes />}
    </>
  );
};

export default SubscriptionChangesPage;
