import React, { useContext } from "react";
import { styled } from "styled-components";

import AppContext from "@/contexts/appContext";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import { useTranslate } from "@/i18n";

const CheckmarkSVG = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3346 4L6.0013 11.3333L2.66797 8"
      stroke="#1F7731"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ProductFeatures: React.FC<{ subscription: Subscription }> = ({
  subscription,
}) => {
  const { catalana } = useContext(AppContext);
  const t = useTranslate(messages);

  const subscriptionFeaturesWithCatalanaDescription = (
    catalana.productFeatures
      ? subscription.product.productFeatures.filter(
          (f) => !!catalana.productFeatures[f.code],
        )
      : []
  ).map((f) => catalana.productFeatures[f.code]);

  return subscriptionFeaturesWithCatalanaDescription.length > 0 ? (
    <Component>
      <Title>{t("title")}</Title>
      <ListWrapper>
        <List>
          {subscriptionFeaturesWithCatalanaDescription.map((f) => (
            <ListItem key={f.description}>
              <Checkmark>{CheckmarkSVG}</Checkmark>
              {f.description}
            </ListItem>
          ))}
        </List>
      </ListWrapper>
    </Component>
  ) : null;
};

const Component = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.h4`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

const ListWrapper = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
`;

const List = styled.ul`
  max-width: 600px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  width: 50%;
  min-width: 240px;
`;

const Checkmark = styled.span`
  margin-right: 5px;
  margin-top: 7px;
`;

const messages = {
  title: {
    nb: "Dette har du tilgang til:",
    nn: "Dette har du tilgang til:",
  },
};

export default ProductFeatures;
