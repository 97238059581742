import { deleteChange } from "@/fetch/subscription/deleteChange";
import { log } from "@/logging/logger";

export const cancelCancellation = async ({
  changeId,
  subscriptionId,
  callbacks,
}: {
  changeId: string;
  subscriptionId: string;
  callbacks: {
    onSuccess: () => void;
    onError: () => void;
  };
}) => {
  const { status, error } = await deleteChange({
    changeId,
    subscriptionId,
  });

  if (status === "SUCCESS") {
    callbacks.onSuccess();
  } else {
    log.error("Cancel cancellation failed", error ?? "Unknown error");
    callbacks.onError();
  }
};
