import dayjs from "dayjs";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

import AddressFieldset, { InputAddress } from "./addressFieldset";
import { addressErrorMessages, validateAddress } from "./addressValidation";

import { InputField } from "@/components//InputField";
import { PrimaryButton } from "@/components/Button";
import DatePicker from "@/components/DatePicker";
import SubHeader from "@/components/SubHeader";
import { TranslationFunction, useTranslate } from "@/i18n";
import { log } from "@/logging/logger";
import routes from "@/routes/routes";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import colors from "@/theme/colors";
import { FormSubmit, useForm } from "@/utils/form";
import { useNavigateToReceipt } from "@/pages/ReceiptPage/components/navigateToReceipt";
import { ReceiptType } from "@/pages/ReceiptPage/receiptTypes";
import { registerAddressChange } from "@/fetch/address/registerAddressChange";

type Props = { subscription: Subscription };

type TemporaryMove = InputAddress & {
  fromDate: string;
  toDate: string;
};

const validateInputAddress = (
  formValues: InputAddress,
  t: TranslationFunction<typeof addressErrorMessages>,
): Partial<InputAddress> => {
  const errors: Partial<InputAddress> = {};
  const addressErrors = validateAddress(formValues);

  addressErrors.forEach((err) => {
    errors[err[0]] = t(err[1]);
  });

  return errors;
};

const TemporaryAddressChange: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const navigate = useNavigate();
  const navigateToReceipt = useNavigateToReceipt();

  const submit: FormSubmit<TemporaryMove> = useCallback(
    async ({ fromDate, toDate, ...address }) => {
      const response = await registerAddressChange({
        subscriptionId: subscription.id,
        addressChangeRequest: {
          address,
          fromDate,
          toDate,
          type: "temporary",
        },
      });

      if (response.status === "success") {
        return navigateToReceipt({
          type: ReceiptType.TemporaryAddressChange,
          address,
          fromDate,
          toDate,
        });
      }
      log.error(`Temporary address change failed. ${response.error}`);
      return navigate(routes.error.path());
    },
    [subscription.id, navigate, navigateToReceipt],
  );

  const initialFormValues: TemporaryMove = {
    streetName: "",
    streetNumber: "",
    entrance: "",
    floor: "",
    apartment: "",
    postalCode: "",
    city: "",
    careOf: "",
    fromDate: dayjs(subscription.earliestDeliveryChange).format("YYYY-MM-DD"),
    toDate: dayjs(subscription.earliestDeliveryChange)
      .add(7, "day")
      .format("YYYY-MM-DD"),
  };

  const {
    propsForField,
    values,
    setValue,
    errors,
    submitForm,
    isSubmitting,
    firstInvalidInput,
  } = useForm(
    initialFormValues,
    addressErrorMessages,
    validateInputAddress,
    submit,
  );

  return (
    <>
      <SubHeader text={t("enterNewAddress")}>
        <Description>{t("contactCustomerService")}</Description>
      </SubHeader>
      <form onSubmit={submitForm} noValidate>
        <AddressFieldset
          propsForField={propsForField}
          errors={errors}
          setValue={setValue}
          firstInvalidInput={firstInvalidInput}
        />
        <InputField
          description={
            <SubHeader text={t("when")}>
              <Description>{t("whenDescription")}</Description>
            </SubHeader>
          }
          error={errors.fromDate}
        >
          <DatePicker
            minDate={subscription.earliestDeliveryChange}
            selectedDate={values.fromDate}
            onChange={(v) => setValue("fromDate", v)}
            $error={!!errors.fromDate}
            $large={true}
          />
        </InputField>
        <InputField
          description={
            <SubHeader text={t("howLong")}>
              <Description>{t("howLongDescription")}</Description>
            </SubHeader>
          }
          error={errors.toDate}
        >
          <DatePicker
            minDate={subscription.earliestDeliveryChange}
            selectedDate={values.toDate}
            onChange={(v) => setValue("toDate", v)}
            $error={!!errors.toDate}
            $large={true}
          />
        </InputField>
        <Info>
          <strong>{t("obs")}</strong> {t("info")}
        </Info>
        <PrimaryButton type="submit" disabled={isSubmitting}>
          {t("submit")}
        </PrimaryButton>
      </form>
    </>
  );
};

const Description = styled.p`
  color: ${colors.grayText};
  margin-top: 0px;
`;

const Info = styled.p`
  margin: 0 0 40px;
  flex: 1;
  align-self: center;
`;

export default TemporaryAddressChange;

const messages = {
  contactCustomerService: {
    nb: "Hvis du skal på ferie med felleslevering kontakt kundeservice.",
    nn: "Viss du skal på ferie med felleslevering kontakt kundeservice.",
  },
  enterNewAddress: {
    nb: "Adressen avisen skal sendes til:",
    nn: "Adressa avisa skal sendast til:",
  },
  when: {
    nb: "Når reiser du bort?",
    nn: "Når reiser du bort?",
  },
  whenDescription: {
    nb: "Levering på ferieadresse fra og med:",
    nn: "Levering på ferieadresse frå og med:",
  },
  howLong: {
    nb: "Hvor lenge blir du borte?",
    nn: "Kor lenge blir du borte?",
  },
  howLongDescription: {
    nb: "Levering på hjemmeadresse fra og med:",
    nn: "Levering på heimeadresse frå og med",
  },
  obs: {
    nb: "OBS!",
    nn: "OBS!",
  },
  info: {
    nb: "Hvis avisen din leveres av postbudet sammen med brevposten, er det viktig at du også registrerer adressen på posten.no.",
    nn: "Om avisa leverast saman med brevposten, må adressa også vere registrert hos posten.no.",
  },
  submit: {
    nb: "Send inn",
    nn: "Send inn",
  },
};
