import React from "react";

import PageHeader from "../../../components/PageHeader";
import { DeliveryComplaint } from "../receiptTypes";

import Receipt from "./receipt";

import { useTranslate } from "@/i18n";
import { dateFormat } from "@/utils/date";

const DeliveryComplaintReceipt: React.FC<DeliveryComplaint> = ({
  reason,
  date,
}) => {
  const t = useTranslate(messages);

  return (
    <>
      <PageHeader>{t("title")}</PageHeader>
      <Receipt>
        <p>{t("reason", { reason: reason })}</p>
        <p>
          {t("description", {
            date: dateFormat(date),
          })}
        </p>
      </Receipt>
    </>
  );
};

export default DeliveryComplaintReceipt;

const messages = {
  title: {
    nb: "Kvittering",
    nn: "Kvittering",
  },
  reason: {
    nb: "Begrunnelse for klage: {{reason}}",
    nn: "Begrunnelse for klage : {{reason}}",
  },
  description: {
    nb: "Din klage er registrert {{date}}",
    nn: "Klaga di er registrert {{date}}",
  },
};
