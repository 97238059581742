import React, { PropsWithChildren } from "react";

/** Wraps elements in meta tags used by adplogger2. These tags enables adplogger2
 * to log what the user have seen and for how long, as well as
 * any clicks made on the element  */

type Props = {
  adpType: string;
  name: string;
  data?: object;
};

const Adplogger2Wrapper: React.FC<PropsWithChildren<Props>> = ({
  adpType,
  name,
  data,
  children,
}) => (
  <div itemScope itemType="https://www.adplogger.no/json-schema/CustomElement">
    <meta
      itemProp="https://www.adplogger.no/json-schema/meta-element#adpType"
      content={adpType || `Warn: adpType not set.`}
    />

    <meta
      itemProp="https://www.adplogger.no/json-schema/custom-element#name"
      content={name || `Warn: Name not set.`}
    />

    {data && (
      <meta
        itemProp="https://www.adplogger.no/json-schema/custom-element#data"
        content={JSON.stringify(data)}
      />
    )}
    {children}
  </div>
);

export default Adplogger2Wrapper;
