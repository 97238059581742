import React from "react";
import { styled } from "styled-components";

import colors from "@/theme/colors";

const StyledInputError = styled.div<{ arrow?: boolean }>`
  position: relative;
  background: ${colors.errorRedLight};
  border: 1px solid ${colors.errorRedLight};
  margin-top: 12px;
  padding: 15px 10px;
  word-break: break-word;

  ${({ arrow }) =>
    arrow &&
    `
  :after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${colors.errorRedLight};
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${colors.errorRedLight};
    border-width: 11px;
    margin-left: -11px;
  }
  `}
`;

type Props = {
  error: string | undefined;
  id?: string;
  arrow?: boolean;
};

const InputError: React.FC<Props> = ({ error, id, ...rest }) =>
  error ? (
    <StyledInputError id={id} {...rest}>
      {error}
    </StyledInputError>
  ) : null;

export default InputError;
