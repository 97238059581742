import React, { useState } from "react";

import TemporaryAddressChange from "./temporaryAddressChange";

import RadioButtonGroup from "@/components/RadioButtonGroup";
import { Sheet } from "@/components/Sheet";
import SubHeader from "@/components/SubHeader";
import { useTranslate } from "@/i18n";
import { Subscription } from "@/fetch/mappers/subscriptionMapper";
import PauseDeliveryForm from "@/pages/PauseDeliveryPage/components/pauseDeliveryForm";

type Props = { subscription: Subscription };

enum Choices {
  deliver,
  pause,
}

const Temporary: React.FC<Props> = ({ subscription }) => {
  const t = useTranslate(messages);
  const [choice, setChoice] = useState<Choices>();

  return (
    <Sheet>
      <SubHeader text={t("choose")} />
      <RadioButtonGroup<Choices>
        groupName=""
        options={[
          {
            value: Choices.deliver,
            labelComponent: <div>{t("deliverChoice")}</div>,
          },
          {
            value: Choices.pause,
            labelComponent: <div>{t("pauseChoice")}</div>,
          },
        ]}
        selected={choice}
        callBack={setChoice}
      />
      {choice === Choices.deliver && (
        <TemporaryAddressChange subscription={subscription} />
      )}
      {choice === Choices.pause && (
        <PauseDeliveryForm subscription={subscription} />
      )}
    </Sheet>
  );
};

export default Temporary;

const messages = {
  title: {
    nb: "Jeg skal reise bort",
  },
  deliverChoice: {
    nb: "Send avisen dit jeg skal",
  },
  pauseChoice: {
    nb: "Ikke send meg papiravisen når jeg er borte",
  },
  choose: {
    nb: "Velg et alternativ",
  },
};
