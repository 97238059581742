import { styled } from "styled-components";

interface Props {
  as: string; // styled-components special feature: switch tag dynamically
  $textColor: "primary" | "secondary";
}

const ThemedText = styled.div<Props>`
  color: ${(props) =>
    props.$textColor === "secondary"
      ? props.theme.secondaryColor
      : props.theme.primaryColor};
`;

export default ThemedText;
