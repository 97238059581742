import React, { PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

import routes from "@/routes/routes";
import { useTranslate } from "@/i18n";

type Props = PropsWithChildren & {
  links?: ReactNode[];
};

const BreadCrumbs: React.FC<Props> = ({ children, links = [] }) => {
  const t = useTranslate(messages);
  const myPageLink = (
    <Link style={{ color: "inherit" }} to={routes.myPage.path()}>
      {t("myPage")}
    </Link>
  );
  const currentLocation = children && <b>{children}</b>;

  const parts = [myPageLink, ...links, currentLocation];
  const crumbs = parts.reduce(
    (acc, curr, index) => {
      acc.push(curr);
      if (index !== parts.length - 1) {
        acc.push(" / ");
      }
      return acc;
    },
    [] as Array<JSX.Element | ReactNode | string>,
  );

  return children ? <Component>{crumbs}</Component> : null;
};

const Component = styled.div`
  padding: 0 25px;

  @media (min-width: 767px) {
    padding: 0;
  }
`;

export default BreadCrumbs;

const messages = {
  myPage: {
    nb: "Min side",
    nn: "Mi side",
  },
};
