import React, { useLayoutEffect, useRef, useState } from "react";

import { FailureBox } from "@/components/InfoBox";
import { useTranslate } from "@/i18n";

const validationErrorMap: Record<string, string> = {
  postal_code: "validation_error_postal_code",
  "period.overlapping": "validation_error_period_overlapping",
  "to_date.invalid": "validation_error_to_date_invalid",
  "from_date.invalid": "validation_error_from_date_invalid",
  "address.too_long": "validation_error_address_too_long",
  unknown: "validation_error_unknown",
};

const serverErrorMessages: Record<string, { nb: string; nn: string }> = {
  validation_error_title: {
    nb: "Kunne ikke lagre",
    nn: "kune ikkje lagre",
  },
  validation_error_postal_code: {
    nb: "Postnummer kan ikke være tomt.",
    nn: "Postnummer kan ikkje vere tomt.",
  },
  validation_error_period_overlapping: {
    nb: "Du har allerede meldt flytting innenfor valgte datoer.",
    nn: "Du har allereie meldt flytting innanfor valgte datoar.",
  },
  validation_error_to_date_invalid: {
    nb: "Til dato er ugyldig.",
    nn: "Til dato er ugyldig.",
  },
  validation_error_from_date_invalid: {
    nb: "Fra dato er ugyldig.",
    nn: "Frå dato er ugyldig.",
  },
  validation_error_unknown: {
    nb: "En ukjent feil har oppstått. Prøv igjen senere eller kontakt kundeservice.",
    nn: "Ein ukjend feil har oppstått. Prøv igjen seinare eller kontakt kundeservice.",
  },
  validation_error_address_too_long: {
    nb: "Gateadressen er for lang.",
    nn: "Gateadressa er for lang.",
  },
};

type Props = {
  error: string;
};

const AddressChangeServerError: React.FC<Props> = ({ error }) => {
  const [shownOnce, setShownOnce] = useState<boolean>();

  const failureBoxRef = useRef<HTMLDivElement>(null);
  const t = useTranslate(serverErrorMessages);

  useLayoutEffect(() => {
    scrollToBox();
  });

  const scrollToBox = () => {
    if (failureBoxRef && failureBoxRef.current && !shownOnce) {
      setShownOnce(true);
      const boxVisiblePos = failureBoxRef.current.offsetTop - 75;
      if (boxVisiblePos < window.scrollY) {
        window.scrollTo({
          top: boxVisiblePos,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <FailureBox title={t("validation_error_title")} boxRef={failureBoxRef}>
      {t(validationErrorMap[error] || validationErrorMap.unknown)}
    </FailureBox>
  );
};

export default AddressChangeServerError;
