import { PaymentMethod } from "@amedia/node-clients-abo";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
import { styled } from "styled-components";

import { useTranslate } from "@/i18n";
import colors from "@/theme/colors";

const Mastercard = styled(FaCcMastercard)`
  color: ${colors.masterCard};
  float: right;
  height: 30px;
  width: 40px;
`;

const Visa = styled(FaCcVisa)`
  color: ${colors.visa};
  float: right;
  height: 30px;
  width: 40px;
`;

const IconContainer = styled.span`
  margin-left: auto;
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
`;

type Props = {
  paymentMethod: PaymentMethod;
};

const PaymentInfo: React.FC<Props> = ({ paymentMethod }) => {
  const t = useTranslate(messages);
  const details = paymentMethod.paymentMethodDetails;

  if (details && "creditCardDetails" in details) {
    const card = details.creditCardDetails;
    const creditCardType = card?.creditCardType || t("creditCard");
    const maskedNumber = card?.maskedNumber;
    const haveAllCreditCardDetails = card?.creditCardType && maskedNumber;

    return (
      <PaymentMethodText>
        {maskedNumber
          ? `${formatCreditcardNumber(maskedNumber)} (${creditCardType})`
          : creditCardType}
        {haveAllCreditCardDetails && (
          <IconContainer>{creditCardIcon(creditCardType)}</IconContainer>
        )}
      </PaymentMethodText>
    );
  }
  return <PaymentMethodText>{paymentMethod.name}</PaymentMethodText>;
};

const creditCardIcon = (creditCardType: string) => {
  switch (creditCardType.toUpperCase()) {
    case "MASTERCARD":
      return <Mastercard />;
    case "VISA":
      return <Visa />;
    default:
      return (
        <>
          <Mastercard />
          <Visa />
        </>
      );
  }
};

const formatCreditcardNumber = (ccNum: string) =>
  ccNum.replace(/(.{4})/g, "$1 ").trim();

const PaymentMethodText = styled.p`
  margin-top: 0;
`;

const messages = {
  creditCard: {
    nb: "Betalingskort",
    nn: "Betalingskort",
  },
};

export default PaymentInfo;
