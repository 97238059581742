import React from "react";
import { styled } from "styled-components";
import { useOutletContext } from "react-router-dom";

import { Sheet } from "@/components/Sheet";
import { useTranslate } from "@/i18n";
import StaffLogo from "@/public/amedia_staff.png";
import colors from "@/theme/colors";
import { AppOutlet } from "@/components/AppRoute/appRoute";

type Props = {
  hasSubscription: boolean;
};

const AmediaStaff: React.FC<Props> = ({ hasSubscription }) => {
  const t = useTranslate(messages);
  const { user } = useOutletContext<AppOutlet>();

  const amediaStaffAccess = user.access.includes("amedia_staff");

  return amediaStaffAccess ? (
    <Sheet
      $borderTopColor={colors.amediaMagenta}
      data-testid="staff-access-card"
    >
      <Logo src={StaffLogo} width="120px" />
      <Heading>{t("heading")}</Heading>
      <p>{hasSubscription ? t("info") : t("infoNoSubscription")}</p>
    </Sheet>
  ) : null;
};

const Heading = styled.h2`
  margin-top: 0;
`;

const Logo = styled.img`
  margin-left: 20px;
  float: right;
`;

export default AmediaStaff;

const messages = {
  heading: {
    nb: "Du har tilgang fordi du er ansatt i Amedia",
  },
  info: {
    nb: 'Din aID har "superkrefter" siden du er ansatt i Amedia! Du har derfor full tilgang til plussartikler og eAvis både på denne og alle andre Amedia-aviser.',
  },
  infoNoSubscription: {
    nb: 'Du har ikke abonnement på denne avisen, men din aID har "superkrefter" siden du er ansatt i Amedia! Du har derfor full tilgang til plussartikler og eAvis både på denne og alle andre Amedia-aviser.',
  },
};
